import React, { PureComponent } from 'react';
import { Menu, Row, Col} from 'antd';
import DynamicPages from './DynamicPages'
import MenuTable from './MenuTable'

export default class Menus extends PureComponent {
  render() {
    return <div>
        <Row>
            <Col span={4}><DynamicPages /></Col>
            <Col span={19}><MenuTable /></Col>  
        </Row>
    </div>
  }
}