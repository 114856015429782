
import React from 'react';
import { Form, Icon, Input, Button,  Upload, message,Tabs,Select} from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // ES6
import { getAboutPageData, putAboutPageData,
    getSalonTabsData, getZagarTabsData, getSubmenuList
 } from '../api';
import { getAccessToken } from '../services/localStorage';

const TabPane = Tabs.TabPane;




class AboutForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            submenuList: [],
            images: [],
            headText: "",
            centeral_text: "",
            centeral_text_two: "",
            centeral_text_two: "",
            footer_components: {
                footer_one: {
                    title: "",
                    text: "",
                    submenuTabs: []
                },
                footer_two: {
                    title: "",
                    text: "",
                    submenuTabs: []
                },
                footer_three: {
                    title: "",
                    text: "",
                    submenuTabs: []
                },
                footer_four: {
                    title: "",
                    text: "",
                    submenuTabs: []
                }
            },
        }


    }

    normFile = e => {
        console.log('Upload event:', e);
        if(e.file.status == 'done') {
            return e.file.response.id;
        }
        
      };


    async componentDidMount() {
        const { data } = await getAboutPageData();
        const salonTabs = await getSalonTabsData();
        const zagarTabs = await getZagarTabsData();
        const submenuList  = await getSubmenuList();

        Object.values(data.footer_components).map((i) => {
            i.submenuTabs = []
            submenuList.data.data.map(s => {
                if(i.submenu_id == s.id && s.design_type_id == 2) {
                    i.submenuTabs = zagarTabs.data.data
                }  else 
                if(i.submenu_id == s.id && s.design_type_id == 4) {
                    i.submenuTabs = salonTabs.data.data
                } 
            })
        })
        this.setState(data);
        this.setState({
            submenuList: submenuList.data.data,
            salonTabs: salonTabs.data.data,
            zagarTabs: zagarTabs.data.data
        });
        // this.props.form.setFieldsValue(this.state)
    }

      handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
          if (!err) {
            try {
                const postData = await putAboutPageData(values)
                message.success('Success')

            } catch (error) {
                message.error('Error', error.response.status)

            }
          }
        });
      }

      selectSubmenu = async (e, param) => {
        this.state.submenuList.map(async(s) => {
            console.log(s,param)
            let footer_components = this.state.footer_components

            if (s.design_type_id === 2 && s.id == e) {
                console.log(this.state.zagarTabs)
                const zagarTabs = this.state.zagarTabs
                // let footer_components = this.state.footer_components
                eval( 'footer_components.' + param + '.submenuTabs' + '=' + JSON.stringify(zagarTabs)) 
                this.setState({footer_components: footer_components})
            }
            else if (s.design_type_id === 4 && s.id == e) {
                const salonTabs = this.state.salonTabs
                // let footer_components = this.state.footer_components
                eval( 'footer_components.' + param + '.submenuTabs' + '=' + JSON.stringify(salonTabs)) 
                this.setState({footer_components: footer_components})
            }
            
             
        })
      }

    render () {
        const uploadProps = {
            action: process.env.REACT_APP_ROOT_API + '/uploads/',
            listType: 'picture',
            name: 'uri',
            headers: {
                Authorization:  getAccessToken(),
            },
          };
        const { getFieldDecorator } = this.props.form;

        return (
            
            <Form onSubmit={this.handleSubmit}>
                
                <Form.Item label="Title">
                {getFieldDecorator('title', {
                    rules: [{
                    required: true, message: 'Please input your title',
                    }],
                    initialValue: this.state.title
                })(
                    <Input     />
                )}
                </Form.Item>

                <Form.Item label="headText">
                {getFieldDecorator('headText', {
                    rules: [{
                    required: true, message: 'Please input your headText',
                    }],
                    initialValue: this.state.headText
                })(
                    <ReactQuill />

                )}
                </Form.Item>

                <Form.Item label="Image one" extra="Image one">
                    {getFieldDecorator('images.image_one', {
                        valuePropName: 'uri',
                        getValueFromEvent: this.normFile,
                        initialValue: this.state.images.image_one
                    })(
                        <Upload {...uploadProps}>
                            <Button>
                                <Icon type="upload" /> Upload
                            </Button>
                        </Upload>
                    )}
                </Form.Item>
                
                <Form.Item label="image_two" extra="image_two">
                    {getFieldDecorator('images.image_two', {
                        valuePropName: 'uri',
                        getValueFromEvent: this.normFile,
                        initialValue: this.state.images.image_two
                    })(
                        <Upload {...uploadProps}>
                            <Button>
                                <Icon type="upload" /> Upload
                            </Button>
                        </Upload>
                    )}
                </Form.Item>  

                <Form.Item label="images.image_three" extra="image_three">
                    {getFieldDecorator('images.image_three', {
                        valuePropName: 'uri',
                        getValueFromEvent: this.normFile,
                        initialValue: this.state.images.image_three

                    })(
                        <Upload {...uploadProps}>
                            <Button>
                                <Icon type="upload" /> Upload
                            </Button>
                        </Upload>
                    )}
                </Form.Item>


                <Form.Item label="Title centeral_title">
                    {getFieldDecorator('centeral_title', {   
                        rules: [{
                        required: true, message: 'Please input your title',
                        }],
                        initialValue: this.state.centeral_title

                    })(
                        <Input     />
                    )}
                </Form.Item>

                <Form.Item label="centeral_text">
                    {getFieldDecorator('centeral_text', {
                        rules: [{
                        required: true, message: 'Please input your centeral_text',
                        }],
                        initialValue: this.state.centeral_text

                    })(
                        <ReactQuill />
                        )}
                </Form.Item>

                <Form.Item label="centeral_text_two">
                    {getFieldDecorator('centeral_text_two', {
                        rules: [{
                        required: true, message: 'Please input your centeral_text_two',
                        }],
                        initialValue: this.state.centeral_text_two

                    })(
                        <ReactQuill />
                        )}
                </Form.Item>

                <Tabs defaultActiveKey="1" >
                    <TabPane tab="Image One" key="1">
                                                {/* 1111111111111111 */}
                <Form.Item label="Title footer_one.title">
                    {getFieldDecorator('footer_components.footer_one.title', {   
                        rules: [{
                        required: true, message: 'Please input your title',
                        }],
                        initialValue: this.state.footer_components.footer_one && this.state.footer_components.footer_one.title

                    })(
                        <Input     />
                    )}
                </Form.Item>

                <Form.Item label="footer_one.text">
                    {getFieldDecorator('footer_components.footer_one.text', {
                        rules: [{
                        required: true, message: 'Please input your text',
                        }],
                        initialValue: this.state.footer_components.footer_one && this.state.footer_components.footer_one.text

                    })(
                        <ReactQuill />
                        )}
                </Form.Item>


                <Form.Item label="Image footer_one.image" extra="Image footer_one.image">
                    {getFieldDecorator('footer_images.image_one', {
                        valuePropName: 'uri',
                        getValueFromEvent: this.normFile,
                        initialValue: this.state.footer_images && this.state.footer_images.image_one

                    })(
                        <Upload {...uploadProps}>
                            <Button>
                                <Icon type="upload" /> Upload
                            </Button>
                        </Upload>
                    )}
                </Form.Item>
                
                <Form.Item label="Select Image one SubMenu" hasFeedback>
                            {getFieldDecorator('footer_components.footer_one.submenu_id', {
                                rules: [{  message: 'Select Image one SubMenu!' }],
                                // initialValue: this.state.images.image_one.submenu_id
                                initialValue: this.state.footer_components.footer_one && this.state.footer_components.footer_one.submenu_id

                            })(
                                <Select placeholder="Please select your Select Image one SubMenu"
                                    onChange={ (e) =>{ this.selectSubmenu(e,'footer_one');} }
                                >
                                    {this.state.submenuList.map(d => (
                                        <Select.Option key={d.id}>{d.name}</Select.Option>
                                    ))}
                                </Select>,
                            )}
                        </Form.Item>

                        <Form.Item label="Select Image one SubMenu Tab" hasFeedback>
                            {getFieldDecorator('footer_components.footer_one.submenu_tab_id', {
                                rules: [{  message: 'Select Image one SubMenu Tab!' }],
                                // initialValue: this.state.images.image_one.submenu_id
                                initialValue: this.state.footer_components.footer_one && this.state.footer_components.footer_one.submenu_tab_id

                            })(
                                <Select placeholder="Please select your Select Image one SubMenu Tab">
                                    {this.state.footer_components.footer_one.submenuTabs.map(d => (
                                        <Select.Option key={d.id}>{d.name}</Select.Option>
                                    ))}
                                </Select>,
                            )}
                        </Form.Item>

                    </TabPane>
                    <TabPane tab="Image Two" key="2">
   {/* 222222222222222222 */}
   <Form.Item label="Title footer_two.title">
                    {getFieldDecorator('footer_components.footer_two.title', {   
                        rules: [{
                        required: true, message: 'Please input your title',
                        }],
                        initialValue: this.state.footer_components.footer_two && this.state.footer_components.footer_two.title

                    })(
                        <Input     />
                    )}
                </Form.Item>

                <Form.Item label="footer_two.text">
                    {getFieldDecorator('footer_components.footer_two.text', {
                        rules: [{
                        required: true, message: 'Please input your text',
                        }],
                        initialValue: this.state.footer_components.footer_two && this.state.footer_components.footer_two.text

                    })(
                        <ReactQuill />
                        )}
                </Form.Item>


                <Form.Item label="Image footer_two.image" extra="Image footer_two.image">
                    {getFieldDecorator('footer_images.image_two', {
                        valuePropName: 'uri',
                        getValueFromEvent: this.normFile,
                        initialValue: this.state.footer_images && this.state.footer_images.image_two

                    })(
                        <Upload {...uploadProps}>
                            <Button>
                                <Icon type="upload" /> Upload
                            </Button>
                        </Upload>
                    )}
                </Form.Item>
                <Form.Item label="Select Image one SubMenu" hasFeedback>
                            {getFieldDecorator('footer_components.footer_two.submenu_id', {
                                rules: [{  message: 'Select Image one SubMenu!' }],
                                // initialValue: this.state.images.image_one.submenu_id
                                initialValue: this.state.footer_components.footer_two && this.state.footer_components.footer_two.submenu_id

                            })(
                                <Select placeholder="Please select your Select Image one SubMenu"
                                    onChange={ (e) =>{ this.selectSubmenu(e,'footer_two');} }
                                >
                                    {this.state.submenuList.map(d => (
                                        <Select.Option key={d.id}>{d.name}</Select.Option>
                                    ))}
                                </Select>,
                            )}
                        </Form.Item>

                        <Form.Item label="Select Image one SubMenu Tab" hasFeedback>
                            {getFieldDecorator('footer_components.footer_two.submenu_tab_id', {
                                rules: [{  message: 'Select Image one SubMenu Tab!' }],
                                // initialValue: this.state.images.image_one.submenu_id
                                initialValue: this.state.footer_components.footer_two && this.state.footer_components.footer_two.submenu_tab_id

                            })(
                                <Select placeholder="Please select your Select Image one SubMenu Tab">
                                    {this.state.footer_components.footer_two.submenuTabs.map(d => (
                                        <Select.Option key={d.id}>{d.name}</Select.Option>
                                    ))}
                                </Select>,
                            )}
                        </Form.Item>
                    </TabPane>
                    <TabPane tab="Image Three" key="3">
                       {/* 3333333333333333333333 */}
                       <Form.Item label="Title footer_three.title">
                    {getFieldDecorator('footer_components.footer_three.title', {   
                        rules: [{
                        required: true, message: 'Please input your title',
                        }],
                        initialValue: this.state.footer_components.footer_three && this.state.footer_components.footer_three.title

                    })(
                        <Input     />
                    )}
                </Form.Item>

                <Form.Item label="footer_three.text">
                    {getFieldDecorator('footer_components.footer_three.text', {
                        rules: [{
                        required: true, message: 'Please input your text',
                        }],
                        initialValue: this.state.footer_components.footer_three && this.state.footer_components.footer_three.text

                    })(
                        <ReactQuill />
                        )}
                </Form.Item>


                <Form.Item label="Image footer_three.image" extra="Image footer_three.image">
                    {getFieldDecorator('footer_images.image_three', {
                        valuePropName: 'uri',
                        getValueFromEvent: this.normFile,
                        initialValue: this.state.footer_images && this.state.footer_images.image_three

                    })(
                        <Upload {...uploadProps}>
                            <Button>
                                <Icon type="upload" /> Upload
                            </Button>
                        </Upload>
                    )}
                </Form.Item>
                <Form.Item label="Select Image one SubMenu" hasFeedback>
                            {getFieldDecorator('footer_components.footer_three.submenu_id', {
                                rules: [{  message: 'Select Image one SubMenu!' }],
                                // initialValue: this.state.images.image_one.submenu_id
                                initialValue: this.state.footer_components.footer_three && this.state.footer_components.footer_three.submenu_id

                            })(
                                <Select placeholder="Please select your Select Image one SubMenu"
                                    onChange={ (e) =>{ this.selectSubmenu(e,'footer_three');} }
                                >
                                    {this.state.submenuList.map(d => (
                                        <Select.Option key={d.id}>{d.name}</Select.Option>
                                    ))}
                                </Select>,
                            )}
                        </Form.Item>

                        <Form.Item label="Select Image one SubMenu Tab" hasFeedback>
                            {getFieldDecorator('footer_components.footer_three.submenu_tab_id', {
                                rules: [{  message: 'Select Image one SubMenu Tab!' }],
                                // initialValue: this.state.images.image_one.submenu_id
                                initialValue: this.state.footer_components.footer_three && this.state.footer_components.footer_three.submenu_tab_id

                            })(
                                <Select placeholder="Please select your Select Image one SubMenu Tab">
                                    {this.state.footer_components.footer_three.submenuTabs.map(d => (
                                        <Select.Option key={d.id}>{d.name}</Select.Option>
                                    ))}
                                </Select>,
                            )}
                        </Form.Item>
                    </TabPane>
                    <TabPane tab="Image Four" key="4">

                                        {/* 44444444444444444444 */}
                                        <Form.Item label="Title footer_four.title">
                    {getFieldDecorator('footer_components.footer_four.title', {   
                        rules: [{
                        required: true, message: 'Please input your title',
                        }],
                        initialValue: this.state.footer_components.footer_four && this.state.footer_components.footer_four.title

                    })(
                        <Input     />
                    )}
                </Form.Item>

                <Form.Item label="footer_four.text">
                    {getFieldDecorator('footer_components.footer_four.text', {
                        rules: [{
                        required: true, message: 'Please input your text',
                        }],
                        initialValue: this.state.footer_components.footer_four && this.state.footer_components.footer_four.text

                    })(
                        <ReactQuill />
                        )}
                </Form.Item>


                <Form.Item label="Image footer_four.image" extra="Image footer_four.image">
                    {getFieldDecorator('footer_images.image_four', {
                        valuePropName: 'uri',
                        getValueFromEvent: this.normFile,
                        initialValue: this.state.footer_images && this.state.footer_images.image_four

                    })(
                        <Upload {...uploadProps}>
                            <Button>
                                <Icon type="upload" /> Upload
                            </Button>
                        </Upload>
                    )}
                </Form.Item>
                <Form.Item label="Select Image one SubMenu" hasFeedback>
                            {getFieldDecorator('footer_components.footer_four.submenu_id', {
                                rules: [{  message: 'Select Image one SubMenu!' }],
                                // initialValue: this.state.images.image_one.submenu_id
                                initialValue: this.state.footer_components.footer_four && this.state.footer_components.footer_four.submenu_id

                            })(
                                <Select placeholder="Please select your Select Image one SubMenu"
                                    onChange={ (e) =>{ 
                                        this.selectSubmenu(e,'footer_four');
                                    } }
                                >
                                    {this.state.submenuList.map(d => (
                                        <Select.Option key={d.id}>{d.name}</Select.Option>
                                    ))}
                                </Select>,
                            )}
                        </Form.Item>

                        <Form.Item label="Select Image one SubMenu Tab" hasFeedback>
                            {getFieldDecorator('footer_components.footer_four.submenu_tab_id', {
                                rules: [{  message: 'Select Image one SubMenu Tab!' }],
                                // initialValue: this.state.images.image_one.submenu_id
                                initialValue: this.state.footer_components.footer_four && this.state.footer_components.footer_four.submenu_tab_id

                            })(
                                <Select placeholder="Please select your Select Image one SubMenu Tab">
                                    {this.state.footer_components.footer_four.submenuTabs.map(d => (
                                        <Select.Option key={d.id}>{d.name}</Select.Option>
                                    ))}
                                </Select>,
                            )}
                        </Form.Item>

                    </TabPane>
                </Tabs>


                 
                 
                           {/* Meta Part       ------------------------- */}
                <Form.Item label="Title meta  ">
                    {getFieldDecorator('meta.title', {
                        rules: [{
                        required: true, message: 'Please input your meta title',
                        }],
                        initialValue: this.state.meta && this.state.meta.title

                    })(
                        <Input     />
                    )}
                </Form.Item>

                <Form.Item label="meta.description">
                    {getFieldDecorator('meta.description', {
                        rules: [{
                        required: true, message: 'Please input your meta.description',
                        }],
                        initialValue: this.state.meta && this.state.meta.description

                    })(
                        <Input.TextArea style={{minHeight: 125}}    />
                    )}
                </Form.Item>

                <Form.Item label="meta.og_image" extra="meta.og_image">
                    {getFieldDecorator('meta.og_image', {
                        valuePropName: 'uri',
                        getValueFromEvent: this.normFile,
                        initialValue: this.state.meta && this.state.meta.og_image

                    })(
                        <Upload {...uploadProps}>
                            <Button>
                                <Icon type="upload" /> Upload
                            </Button>
                        </Upload>
                    )}
                </Form.Item>
                


                <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
                </Form.Item>

            </Form>
        )
    }
}



export default Form.create({ name: 'aboutForm' })(AboutForm);