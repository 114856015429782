
import React from 'react';
import { Form, Icon, Input, Button, Upload, Select, message} from 'antd';
import { 
    findAutozagarData,
    getSubmenuHeadersData,
    putAutozagarData,
    postAutozagarData,
    deleteFile

} from '../api';
import { getAccessToken } from '../services/localStorage';

import ReactQuill from 'react-quill'

class AutozagarEditForm extends React.Component {
    state = {
        isNewRow: false ,
        product_list: [],
        submenuHeaders: []
    }

    changeVal = (value, id, dataIndex) => {
        const product_list = this.state.product_list
        product_list.forEach(p => {
            if(id == p.id) 
             p[dataIndex]  = value; 
        })
        this.setState({product_list: product_list})
    }

    handleDeleteImage = async ( param ) => {
        console.log(this.state)
        if(param == '') {
            message.info('Already empty')
            return;
        }
            
        if (param === this.state.image) {
            try {
                const deleteData = await deleteFile(param)
                message.success('success ' + deleteData.status);
            } catch (error) {
                message.error('error', error.status);
            }
            this.setState({image: ''})

        }
        if (param === this.state.image_two) {
            try {
                const deleteData = await deleteFile(param)
                message.success('success ' + deleteData.status);
            } catch (error) {
                message.error('error', error.status);
            }
            this.setState({image_two: ''})
        }
      }

    handleAdd = ( ) => {
        const product_list = this.state.product_list;
        let id = ''
        if(product_list.length == 0) {
            id = 1
        } else {
            id = product_list[product_list.length - 1 ].id + 1;     
        }
        const newRow = {
            id: id, 
            product: '',
            price: ''
        }
        product_list.push(newRow)
        this.setState({product_list: product_list})

    }
    handleDelete = ( id ) => {
        const product_list = this.state.product_list;
        this.setState({ product_list: product_list.filter(item => item.id !== id) });
    }

    normFile = e => {
        if(e.file.status == 'done') {
            return e.file.response.id;
        }
        
    };
    
    async componentDidMount() {
        const id = this.props.match.params.id
        const submenuHeaders = await getSubmenuHeadersData();
        if (id == 'new') {
            const emptyData = {
                title: '',
                text: '',
                product_text: '',
                image: null,
                product_list: [],
                image: "",
                image_two: ""
            }
            this.setState({
                ...emptyData,
                isNewRow: true,
                submenuHeaders: submenuHeaders.data.data
            });
        } else {
            const { data } = await findAutozagarData(id);
            const submenuHeaders = await getSubmenuHeadersData();
            this.setState({
                ...data,
                submenuHeaders: submenuHeaders.data.data
            });
        }
        
    }

      handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if(values.image === undefined) {
                values.image = ""
            }
            if(values.image_two === undefined) {
                values.image_two = ""
            }
            
          if (!err) {
            try {
                values.product_list = this.state.product_list
                if(this.state.isNewRow) {
                    const postData = await postAutozagarData(values)
                    message.success('success ' + postData.status);
                    this.props.history.push('/autozagar')

                }  else {
                    
                    values.id = this.state.id
                    const postData = await putAutozagarData(values)
                    message.success('success ' + postData.status);
                    this.props.history.push('/autozagar')

                }
                

            } catch (error) {
                message.error('error', error.status);
            }
          }
        });
      }


    render () {

        const uploadProps = {
            action: process.env.REACT_APP_ROOT_API + '/uploads/',
            listType: 'picture',
            name: 'uri',
            headers: {
                Authorization: getAccessToken(),
            },
            onRemove: async file => {
                try {
                   const deleteData = await deleteFile(file.name)
                   message.success('success ' + deleteData.status);
               } catch (error) {
                   message.error('error', error.status);
               }
           },
        };


        let selectValues = ''
        selectValues = this.state.submenuHeaders.map(s => {
            return  <Select.Option value={s.id} >{s.title + ' ' + s.id}</Select.Option>
        })

        const { getFieldDecorator } = this.props.form;

        return (
             
            <Form onSubmit={this.handleSubmit}>
                <Form.Item label="Submenu Header">
                            {getFieldDecorator('submenu_headers_id', {
                            rules: [{
                                required: true, message: 'Please Select',
                            }],
                                initialValue: this.state.submenu_headers_id ,
                                
                            })(
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Please select"
                                    >
                                    {selectValues}
                                </Select>
                            )}
                </Form.Item>

                <Form.Item label="Content Title">
                    {getFieldDecorator('content_title', {
                        rules: [{
                            required: true, message: 'Please input your title',
                        }],
                        initialValue: this.state.content_title
                    }, )(
                        <Input />
                    )}
                </Form.Item>
                
                <Form.Item label="Conetent Text One">
                    {getFieldDecorator('content_text_one', {
                        rules: [{
                            required: true, message: 'Please input ',
                        }],
                        initialValue: this.state.content_text_one
                    })(
                        <ReactQuill />
                    )}
                </Form.Item>   

                <Form.Item label="Conetent Text Two">
                    {getFieldDecorator('content_text_two', {
                        rules: [{
                            required: true, message: 'Please input ',
                        }],
                        initialValue: this.state.content_text_two
                    })(
                        <ReactQuill />
                    )}
                </Form.Item>

                
                <Form.Item label="Title">
                    {getFieldDecorator('title', {
                        rules: [{
                            required: true, message: 'Please input your title',
                        }],
                        initialValue: this.state.title
                    }, )(
                        <Input />
                    )}
                </Form.Item>

                <Form.Item label="Head Text">
                    {getFieldDecorator('text', {
                        rules: [{
                            required: true, message: 'Please input your Head Text',
                        }],
                        initialValue: this.state.text
                    })(
                        <ReactQuill />
                    )}
                </Form.Item>
                <Form.Item label="Product Text">
                    {getFieldDecorator('product_text', {
                        rules: [{
                            required: true, message: 'Please input your Head Text',
                        }],
                        initialValue: this.state.product_text
                    })(
                        <ReactQuill />
                    )}
                </Form.Item>


                <Form.Item label="Image one" extra="Image one">
                            {getFieldDecorator('image', {
                                valuePropName: 'uri',
                                getValueFromEvent: this.normFile,
                                initialValue: this.state.image ,
                                // rules: [{
                                //     required: true, message: 'Please Select',
                                // }],
                            })(
                                <Upload {...uploadProps}>
                                    <Button>
                                        <Icon type="upload" /> Upload
                                    </Button>
                                </Upload>
                            )}
                </Form.Item>
                <Button onClick={() => this.handleDeleteImage(this.state.image)} type="primary" style={{ marginLeft: 16 }}>
                    Delete Image One
                </Button>
                <Form.Item label="Image Two" extra="Image Two">
                            {getFieldDecorator('image_two', {
                                valuePropName: 'uri',
                                getValueFromEvent: this.normFile,
                                initialValue: this.state.image ,
                                // rules: [{
                                //     required: true, message: 'Please Select',
                                // }],
                            })(
                                <Upload {...uploadProps}>
                                    <Button>
                                        <Icon type="upload" /> Upload
                                    </Button>
                                </Upload>
                            )}
                </Form.Item>

                <Button onClick={() => this.handleDeleteImage(this.state.image_two)} type="primary" style={{ marginLeft: 16 }}>
                    Delete Image Two
                </Button>

                <Form.Item label="Product List" extra="Product List">
                    { <div>
                        <Button onClick={this.handleAdd} type="primary" style={{ marginBottom: 16 }}>
                        Add a row
                        </Button>
                        {this.state.product_list.map(p => {
                            return (<div>
                                        <Input value={p.product} style={{width: 200}} onChange={e => this.changeVal(e.target.value, p.id, 'product')}/>
                                        <Input value={p.price}   style={{width: 150}} onChange={e => this.changeVal(e.target.value, p.id , 'price')}/>
                                        <Button onClick={() => this.handleDelete(p.id)} type="primary" style={{ marginLeft: 16 }}>
                                            Delete Row
                                        </Button>
                                    </div>
                            )   
                        })}
                      </div>
                    }
                </Form.Item> 

                <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
                </Form.Item>

            </Form>
        )
    }
}



export default Form.create({ name: 'autozagarEditFormEdit' })(AutozagarEditForm);