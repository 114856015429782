import React, { PureComponent } from 'react';
import { Menu, Row, Col} from 'antd';
import { Link } from 'react-router-dom';
import { getConatctsPageData } from '../api';
import StaticMenus from './StaticPages'
import ConatctsTable from './contactsTable'

export default class Conatcts extends PureComponent {

  render() {
    return <div>
        <Row>
            <Col span={4}><StaticMenus /></Col>
            <Col span={19}>
                <ConatctsTable />
            </Col>  
        </Row>
    </div>
  }
}