
import React from 'react';
import { Form, Input, Button, message} from 'antd';
import {  getHeaderPageData, putHeaderPageData } from '../api';


class HeaderPageForm extends React.Component {

    state = {
        title: "",

    }

    async componentDidMount() {
        const { data } = await getHeaderPageData();
        this.setState(data);
    }

      handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
          if (!err) {
            try {
                const postData = await putHeaderPageData(values)
                message.success('Success')
            } catch (error) { 
                message.error('Error', error.response.status)
              
            }
          }
        });
      }

    render () {

        const { getFieldDecorator } = this.props.form;

        return (
             
            <Form onSubmit={this.handleSubmit}>
                
                <Form.Item label="Title ">
                    {getFieldDecorator('title', {
                        rules: [{
                        required: true, message: 'Please input your meta title',
                        }],
                        initialValue: this.state.title

                    })(
                        <Input     />
                    )}
                </Form.Item>


                <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
                </Form.Item>

            </Form>
        )
    }
}



export default Form.create({ name: 'headerPageForm' })(HeaderPageForm);