/* eslint-disable import/first */


import React from 'react';
import { Table, Input, Popconfirm, Form, Button, Select, Upload, Icon, message } from 'antd';
import { getAccessToken } from '../services/localStorage';

const EditableContext = React.createContext();
import { 
    getSubmenuHeadersData,
    deleteSubmenuHeadersData,
    putSubmenuHeadersData,
    postSubmenuHeadersData,
    getSubmenusForHeadersData
     } from '../api';

class EditableCell extends React.Component {

    normFile = e => {
        console.log('Upload event:', e);
        if(e.file.status == 'done') {
            return e.file.response.id;
        }
        
    };

    
  getInput = () => {
    if (this.props.inputType === 'select') {
        let selectValues = ''
        selectValues = this.props.submenus.map(m => {
            return  <Select.Option value={m.id} >{m.name}</Select.Option>
        })
        return   <Select
                    style={{ width: '200px' }}
                    placeholder="Please select"
                    >
                    {selectValues}
                </Select>;
    } else if(this.props.inputType === 'textArea') {
        return <Input.TextArea   style={{ width: '416px', height: '216px' }}/>
    } else if(this.props.inputType === 'image' ) {
        const  uploadProps = {
            action: process.env.REACT_APP_ROOT_API + '/uploads/',
            listType: 'picture',
            name: 'uri',
            headers: {
                Authorization: getAccessToken(),
            }
        };

        return  <Upload {...uploadProps}>
                    <Button>
                        <Icon type="upload" /> Upload
                    </Button>
                </Upload>;
    }
    return <Input  style={{ width: '200px' }}    />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      submenus,
      ...restProps
    } = this.props;

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ],
              initialValue: record[dataIndex],
              getValueFromEvent: inputType === 'image' ?  this.normFile : ''

            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}


class SubmenuHeadersTable extends React.Component {
    constructor(props) {

        super(props)
        this.state = {
             data: [], 
             submenus: [], 
             editingKey: '',
             isNewRow: false };
        this.columns = [
            {
                title: 'title',
                dataIndex: 'title',
                width: '20%',
                editable: true,
            },
            {
                title: 'text',
                dataIndex: 'text',
                width: '60%',
                editable: true,
            },
            {
                title: 'image One',
                dataIndex: 'image_one',
                width: 5,
                editable: true,
                render: (text,record) => {
                    return (
                        <p>{'image'}</p>
                    )
                    
                }
            },
            {
                title: 'image Two',
                dataIndex: 'image_two',
                width: 5,
                editable: true,
                render: (text,record) => {
                    return (
                        <p>{'image'}</p>
                    )
                    
                }
            },
            {
                title: 'image Three',
                dataIndex: 'image_three',
                width: 5,
                editable: true,
                render: (text,record) => {
                    return (
                        <p>{'image'}</p>
                    )
                    
                }
            },
            {
                title: 'SubMenu',
                dataIndex: 'sub_menu_id',
                width: 5,

                editable: true,
                render: (text,record) => {
                    let submenuText = '' 
                    this.state.submenus.forEach(function(s){
                        if (record.sub_menu_id == s.id) 
                        submenuText = s.name
                    })
                    return (
                        <p>{submenuText}</p>
                    ) 
                }
            },
            {
                title: 'operation',
                dataIndex: 'operation',
                render: (text, record) => {
                const { editingKey } = this.state;
                const editable = this.isEditing(record);
                return editable ? (
                    <span>
                    <EditableContext.Consumer>
                        {form => (
                        <a
                            href="javascript:;"
                            onClick={() => this.save(form, record.id)}
                            style={{ marginRight: 8 }}
                        >
                            Save
                        </a>
                        )}
                    </EditableContext.Consumer>
                    <Popconfirm title="Sure to cancel?" onConfirm={() => this.cancel(record.id)}>
                        <a>Cancel</a>
                    </Popconfirm>
                    </span>
                ) : (
                    <a disabled={editingKey !== ''} onClick={() => this.edit(record.id)}>
                    Edit
                    </a>
                );
                },
            },
            {
                title: 'delete',
                dataIndex: 'delete',
                render: (text, record) =>
                  this.state.data.length >= 1 ? (
                    <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record.id)}>
                      <a href="javascript:;">Delete</a>
                    </Popconfirm>
                  ) : null,
              },
        ];


    }


    async componentDidMount() {
        const { data } = await getSubmenuHeadersData();
        const  submenus  = await getSubmenusForHeadersData();
        this.setState({
            data: data.data,
            submenus: submenus.data
        });

        this.props.form.setFieldsValue(this.state)
    }

    isEditing = record => record.id === this.state.editingKey;

    cancel = (id) => {
        if(this.state.isNewRow) {
            const data = this.state.data;
            this.setState({ data: data.filter(item => item.id !== id) });
        }
        this.setState({ editingKey: '' ,isNewRow: false});
    };

    handleDelete = async id => {
        const data = this.state.data;
        if(!this.state.isNewRow) {
            try {
                const requestData = await  deleteSubmenuHeadersData(id)
                this.setState({ data: data.filter(item => item.id !== id) });

                message.success('Success')
             } catch (err) {
                 message.error('Something Went Wrong ' + err.response.status)
             }
            
        }
    };

    handleAdd = () => {
        if (!this.state.isNewRow) {
            const  data = this.state.data;
            let id = 1
            if(data.length > 0) {
                id = data[data.length - 1] !== undefined ? data[data.length - 1].id + 1 : 1
            }
            const newData = {
                id: id,
                title: '',
                text: '',
                image_one: '',
                image_two: '',
                image_three: '',
                sub_menu_id: '',
                
            };
            this.setState({
                data: [...data, newData],
                editingKey: newData.id,
                isNewRow: true
            });
        }
    };


    save(form, key) {
        form.validateFields( async (error, row) => {
        if (error) {
            return;
        }
        const newData = [...this.state.data];
        const index = newData.findIndex(item => key === item.id);
        if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, {
            ...item,
            ...row,
            });


            if(this.state.isNewRow) {
                try {
                    const requestData = await postSubmenuHeadersData(newData[index])
                    this.setState({ data: newData, editingKey: '' , isNewRow: false});
                    message.success('Success')
                 } catch (err) {
                     message.error('Something Went Wrong ' + err.response.status)
                 } 
            } else {
                
                try {
                    const requestData = await putSubmenuHeadersData(newData[index])
                    this.setState({ data: newData, editingKey: '' , isNewRow: false});
                    message.success('Success')
                 } catch (err) {
                     message.error('Something Went Wrong ' + err.response.status)
                 } 
            }

            
        } else {

            if(this.state.isNewRow) {
                try {
                    const requestData = await  postSubmenuHeadersData(newData[index])
                    newData.push(row);
                    this.setState({ data: newData, editingKey: '', isNewRow: false });
                    message.success('Success')
                 } catch (err) {
                     message.error('Something Went Wrong ' + err.response.status)
                 } 
            } else {
                try {
                    const requestData = await  putSubmenuHeadersData(newData[index])
                    newData.push(row);
                    this.setState({ data: newData, editingKey: '', isNewRow: false });
                    message.success('Success')
                 } catch (err) {
                     message.error('Something Went Wrong ' + err.response.status)
                 }
                
            }
            

        }
        });
    }

    edit(key) {

        this.setState({ editingKey: key });
    }

    render() {
        const components = {
        body: {
            cell: EditableCell,
        },
        };
        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            let inputType = 'text'
            if(col.dataIndex === 'image_one' || col.dataIndex === 'image_two' ||  col.dataIndex === 'image_three') {
                inputType = 'image'
            } else if( col.dataIndex === 'sub_menu_id' ) {
                inputType = 'select'
            } else if( col.dataIndex === 'text' ) {
                inputType = 'textArea'
            } else {
                inputType = 'text'
            }
            return {
                ...col,
                onCell: record => ({
                record,
                inputType: inputType,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: this.isEditing(record),
                submenus: this.state.submenus
                }),
            };
        });

        return (
            <div>
                <Button onClick={this.handleAdd} type="primary" style={{ marginBottom: 16 }}>
                Add a row
                </Button>
                <EditableContext.Provider value={this.props.form}>
                    <Table
                    components={components}
                    bordered
                    dataSource={this.state.data}
                    columns={columns}
                    rowClassName="editable-row"
                    pagination={false}
                    />
                </EditableContext.Provider>
            </div>
            
        );
    }

}


export default Form.create({ name: 'submenuHeadersTable' })(SubmenuHeadersTable);

