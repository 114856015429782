
import React from 'react';
import { Form, Input, Button, Select, message} from 'antd';

import { 
    findZagarData,
    getSubmenuHeadersData,
    putZagarData,
    postZagarData

} from '../api';

import ReactQuill from 'react-quill'

class ZagarEditForm extends React.Component {
    state = {
        isNewRow: false ,
        submenuHeaders: []
    }


    async componentDidMount() {
        const id = this.props.match.params.id
        const submenuHeaders = await getSubmenuHeadersData();
        if (id == 'new') {
            const emptyData = {
                c_title: '', 
                c_content: '', 
                f_title_one: '', 
                f_text_one: '', 
                f_title_two: '', 
                f_text_two: '', 
                f_title_three: '', 
                f_text_three: '', 
                f_title_four: '', 
                f_text_four: '', 
                name: '', 
            
            }
            this.setState({
                ...emptyData,
                isNewRow: true,
                submenuHeaders: submenuHeaders.data.data
            });
        } else {
            const { data } = await findZagarData(id);
            const submenuHeaders = await getSubmenuHeadersData();
            this.setState({
                ...data,
                submenuHeaders: submenuHeaders.data.data
            });
        }
        
    }

      handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            
          if (!err) {
            try {
                if(this.state.isNewRow) {
                    const postData = await postZagarData(values)
                    message.success('success ' + postData.status);
                    this.props.history.push('/zagar-block')

                }  else {
                    
                    values.id = this.state.id
                    const postData = await putZagarData(values)
                    message.success('success ' + postData.status);
                    this.props.history.push('/zagar-block')

                }
                

            } catch (error) {
                message.error('error', error.response.status);
            }
          }
        });
      }


    render () {

        let selectValues = ''
        selectValues = this.state.submenuHeaders.map(s => {
            return  <Select.Option value={s.id} >{s.title + ' ' + s.id}</Select.Option>
        })

        const { getFieldDecorator } = this.props.form;

        return (
             
            <Form onSubmit={this.handleSubmit}>
                <Form.Item label="Submenu Headers">
                            {getFieldDecorator('submenu_headers_id', {
                            rules: [{
                                required: true, message: 'Please Select',
                            }],
                                initialValue: this.state.submenu_headers_id ,
                                
                            })(
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Please select"
                                    >
                                    {selectValues}
                                </Select>
                            )}
                </Form.Item>
                <Form.Item label="name">
                    {getFieldDecorator('name', {
                        rules: [{
                            required: true, message: 'Please input your name',
                        }],
                        initialValue: this.state.name
                    }, )(
                        <Input />
                    )}
                </Form.Item>
                
                <Form.Item label="Centeral Title">
                    {getFieldDecorator('c_title', {
                        rules: [{
                            required: true, message: 'Please input your title',
                        }],
                        initialValue: this.state.c_title
                    }, )(
                        <Input />
                    )}
                </Form.Item>

                <Form.Item label="Centeral Content">
                    {getFieldDecorator('c_content', {
                        rules: [{
                            required: true, message: 'Please input ',
                        }],
                        initialValue: this.state.c_content
                    })(
                        <ReactQuill />
                    )}
                </Form.Item>

                <Form.Item label="Footer Title One">
                    {getFieldDecorator('f_title_one', {
                        rules: [{
                            required: true, message: 'Please input ',
                        }],
                        initialValue: this.state.f_title_one
                    })(
                        <Input />
                    )}
                </Form.Item>
                
                <Form.Item label="Text One">
                    {getFieldDecorator('f_text_one', {
                        rules: [{
                            required: true, message: 'Please input ',
                        }],
                        initialValue: this.state.f_text_one
                    })(
                        <ReactQuill />
                    )}
                </Form.Item>

                <Form.Item label="Footer Title Two">
                    {getFieldDecorator('f_title_two', {
                        rules: [{
                            required: true, message: 'Please input ',
                        }],
                        initialValue: this.state.f_title_two
                    })(
                        <Input />
                    )}
                </Form.Item>
                
                <Form.Item label="Text two">
                    {getFieldDecorator('f_text_two', {
                        rules: [{
                            required: true, message: 'Please input ',
                        }],
                        initialValue: this.state.f_text_two
                    })(
                        <ReactQuill />
                    )}
                </Form.Item>

                <Form.Item label="Footer Title three">
                    {getFieldDecorator('f_title_three', {
                        rules: [{
                            required: true, message: 'Please input ',
                        }],
                        initialValue: this.state.f_title_three
                    })(
                        <Input />
                    )}
                </Form.Item>
                
                <Form.Item label="Text three">
                    {getFieldDecorator('f_text_three', {
                        rules: [{
                            required: true, message: 'Please input ',
                        }],
                        initialValue: this.state.f_text_three
                    })(
                        <ReactQuill />
                    )}
                </Form.Item>

                <Form.Item label="Footer Title four">
                    {getFieldDecorator('f_title_four', {
                        rules: [{
                            required: true, message: 'Please input ',
                        }],
                        initialValue: this.state.f_title_four
                    })(
                        <Input />
                    )}
                </Form.Item>
                
                <Form.Item label="Text four">
                    {getFieldDecorator('f_text_four', {
                        rules: [{
                            required: true, message: 'Please input ',
                        }],
                        initialValue: this.state.f_text_four
                    })(
                        <ReactQuill />
                    )}
                </Form.Item>


                <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
                </Form.Item>

            </Form>
        )
    }
}



export default Form.create({ name: 'zagarEditForm' })(ZagarEditForm);