import React, { PureComponent } from 'react';
import { Menu, Row, Col} from 'antd';
import DynamicPages from './DynamicPages'
import SalonTabsTable from './SalonTabsTable'

export default class SalonTabs extends PureComponent {
  state = {};

  async componentDidMount() {

  }

  render() {
    return <div>
        <Row>
            <Col span={4}><DynamicPages /></Col>
            <Col span={19}><SalonTabsTable /></Col>  
        </Row>
    </div>
  }
}