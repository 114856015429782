import React, { PureComponent } from 'react';
import { Menu, Row, Col} from 'antd';
import DynamicPages from './DynamicPages'
import SubmenuHeadersTable from './SubmenuHeadersTable'

export default class SubmenuHeaders extends PureComponent {
  state = {};

  async componentDidMount() {
    // this.setState({
    // });
  }

  render() {
    return <div>
        <Row>
            <Col span={4}><DynamicPages /></Col>
            <Col span={19}><SubmenuHeadersTable /></Col>  
        </Row>
    </div>
  }
}