/* eslint-disable import/first */

import React from 'react';
import { Table, Input,Popconfirm, Form, Button, Select } from 'antd';
const EditableContext = React.createContext();
import { 
    getConatctsServicePageData,
     getConatctsPageData,
      putConatctsServicePageData,
       deleteConatctsServicePageData,
       postConatctsServicePageData
     } from '../api';

class EditableCell extends React.Component {

  getInput = () => {
    if (this.props.inputType === 'select') {
        const selectValues = this.props.contacts.map(r => {
            return  <Select.Option value={r.id} >{r.location}</Select.Option>
         })
      return   <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    >
                    {selectValues}
                </Select>;
    }
    return <Input />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      contacts,
      ...restProps
    } = this.props;

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ],
              initialValue: this.props.inputType === 'select' ?  this.props.record.contacts.map(r => {
                return  r.id
             }) : record[dataIndex],
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}


class ContactsServiceTable extends React.Component {
    constructor(props) {

        super(props)
        this.state = {
             data: [], 
             allContacts: [], 
             editingKey: '',
             isNewRow: false };
        this.columns = [
            {
                title: 'name',
                dataIndex: 'name',
                width: '20%',
                editable: true,
            },
            {
                title: 'contacts',
                dataIndex: 'contacts',
                width: '60%',
                editable: true,
         
                render: (text,record) => {
                    const contactArray= record.contacts.map(r => {
                        return r.location
                    })
                    const fullText = contactArray.join();
                    return (
                        <p>{fullText}</p>
                    )
                    
                }
            },
            {
                title: 'operation',
                dataIndex: 'operation',
                render: (text, record) => {
                const { editingKey } = this.state;
                const editable = this.isEditing(record);
                return editable ? (
                    <span>
                    <EditableContext.Consumer>
                        {form => (
                        <a
                            href="javascript:;"
                            onClick={() => this.save(form, record.id)}
                            style={{ marginRight: 8 }}
                        >
                            Save
                        </a>
                        )}
                    </EditableContext.Consumer>
                    <Popconfirm title="Sure to cancel?" onConfirm={() => this.cancel(record.id)}>
                        <a>Cancel</a>
                    </Popconfirm>
                    </span>
                ) : (
                    <a disabled={editingKey !== ''} onClick={() => this.edit(record.id)}>
                    Edit
                    </a>
                );
                },
            },
            {
                title: 'delete',
                dataIndex: 'delete',
                render: (text, record) =>
                  this.state.data.length >= 1 ? (
                    <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record.id)}>
                      <a href="javascript:;">Delete</a>
                    </Popconfirm>
                  ) : null,
              },
        ];


    }


    async componentDidMount() {
        const { data } = await getConatctsServicePageData();
        const  allContacts  = await getConatctsPageData();

        this.setState({data: data, allContacts: allContacts.data.data});

        this.props.form.setFieldsValue(this.state)
    }

    isEditing = record => record.id === this.state.editingKey;

    cancel = (id) => {
        if(this.state.isNewRow) {
            const data = this.state.data;
            this.setState({ data: data.filter(item => item.id !== id) });
        }
        this.setState({ editingKey: '' ,isNewRow: false});
    };

    handleDelete = id => {
        const data = this.state.data;
        this.setState({ data: data.filter(item => item.id !== id) });
        if(!this.state.isNewRow) {
            deleteConatctsServicePageData(id)
        }
    };

    handleAdd = () => {
        if (!this.state.isNewRow) {
            const  data = this.state.data;
            let id = 1
            if(data.length > 0) {
                id = data[data.length - 1] !== undefined ? data[data.length - 1].id + 1 : 1
            }
            const newData = {
                id: id,
                name: '',
                contacts: [],
                
            };

            this.setState({
                data: [...data, newData],
                editingKey: newData.id,
                isNewRow: true
            });
        }
    };


    save(form, key) {
        form.validateFields((error, row) => {
        if (error) {
            return;
        }
        const newData = [...this.state.data];
        const index = newData.findIndex(item => key === item.id);
        if (index > -1) {
            const item = newData[index];

            newData.splice(index, 1, {
            ...item,
            ...row,
            });

            if(row.contacts !== undefined) {
                const currentContacts = []
                this.state.allContacts.forEach(c => {
                    if(row.contacts.includes(c.id)) {
                        currentContacts.push(c)
                    }
                })
                newData[index].contacts = currentContacts
            }

            if(this.state.isNewRow) {
                // postConatctsData(newData[index]);
                postConatctsServicePageData(newData[index])
            } else {
                putConatctsServicePageData(newData[index])
            }

            this.setState({ data: newData, editingKey: '' , isNewRow: false});
            
        } else {
            if(row.contacts !== undefined) {
                const currentContacts = []
                this.state.allContacts.forEach(c => {
                    if(row.contacts.includes(c.id)) {
                        currentContacts.push(c)
                    }
                    // return null;
                })
                newData[index].contacts = currentContacts

            }
            if(this.state.isNewRow) {
                postConatctsServicePageData(newData[index])
            } else {
                putConatctsServicePageData(newData[index])
            }
            
            newData.push(row);
            this.setState({ data: newData, editingKey: '', isNewRow: false });

        }
        });
    }

    edit(key) {

        this.setState({ editingKey: key });
    }

    render() {
        const components = {
        body: {
            cell: EditableCell,
        },
        };
        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                record,
                inputType: col.dataIndex === 'contacts' ? 'select' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: this.isEditing(record),
                contacts: this.state.allContacts
                }),
            };
        });

        return (
            <div>
                <Button onClick={this.handleAdd} type="primary" style={{ marginBottom: 16 }}>
                Add a row
                </Button>
                <EditableContext.Provider value={this.props.form}>
                    <Table
                    components={components}
                    bordered
                    dataSource={this.state.data}
                    columns={columns}
                    rowClassName="editable-row"
                    pagination={false}
                    />
                </EditableContext.Provider>
            </div>
            
        );
    }

}


export default Form.create({ name: 'contactsServiceTable' })(ContactsServiceTable);

