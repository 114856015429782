
import React from 'react';
import { Form, Icon, Input, Button, Upload, message} from 'antd';
import {  getContactsMeta, putContactsMeta } from '../api';
import { getAccessToken } from '../services/localStorage';


class MetaContactsForm extends React.Component {

    state = {
        meta: [],

    }

    normFile = e => {
        console.log('Upload event:', e);

        if(e.file.status == 'done') {
            return e.file.response.id;
        }
        
      };

    async componentDidMount() {
        const { data } = await getContactsMeta();
        this.setState(data);
    }

      handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
          if (!err) {
            try {
                const postData = await putContactsMeta(values)
                message.success('Success')
            } catch (error) { 
                message.error('Error', error.response.status)
              
            }
          }
        });
      }

    render () {
        const uploadProps = {
            action: process.env.REACT_APP_ROOT_API + '/uploads/',
            listType: 'picture',
            name: 'uri',
            headers: {
                Authorization: getAccessToken(),
            },
          };



        const { getFieldDecorator } = this.props.form;

        return (
             
            <Form onSubmit={this.handleSubmit}>
                
                
                {/* Meta Part       ------------------------- */}
                <Form.Item label="Title meta  ">
                    {getFieldDecorator('meta.title', {
                        rules: [{
                        required: true, message: 'Please input your meta title',
                        }],
                        initialValue: this.state.meta.title

                    })(
                        <Input     />
                    )}
                </Form.Item>

                <Form.Item label="meta.description">
                    {getFieldDecorator('meta.description', {
                        rules: [{
                        required: true, message: 'Please input your meta.description',
                        }],
                        initialValue: this.state.meta.description

                    })(
                        <Input.TextArea style={{minHeight: 125}}    />
                    )}
                </Form.Item>

                <Form.Item label="meta.og_image" extra="meta.og_image">
                    {getFieldDecorator('meta.og_image', {
                        valuePropName: 'uri',
                        getValueFromEvent: this.normFile,
                        initialValue: this.state.meta && this.state.meta.og_image

                    })(
                        <Upload {...uploadProps}>
                            <Button>
                                <Icon type="upload" /> Upload
                            </Button>
                        </Upload>
                    )}
                </Form.Item>




                <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
                </Form.Item>

            </Form>
        )
    }
}



export default Form.create({ name: 'metaContactsForm' })(MetaContactsForm);