import React, { PureComponent } from 'react';
import { Row, Col} from 'antd';
import DynamicPages from './DynamicPages'
import ZagarBlocksTable from './ZagarBlocksTable'

export default class SalonBlocks extends PureComponent {
  state = {};

  async componentDidMount() {

  }

  render() {
    return <div>
        <Row>
            <Col span={4}><DynamicPages /></Col>
            <Col span={19}><ZagarBlocksTable  /></Col>  
        </Row>
    </div>
  }
}