import axios from '../services/axios';

import { getAccessToken } from '../services/localStorage';

export const signIn = data => axios().post('/authentication', { ...data, strategy: 'local' }); 

export const signOut = () => axios().delete('/authentication'); 

export const getHomePageData = () => axios().get('/home');
export const putHomePageData = homeData => axios().put('/home', {...homeData}, {headers: {'Authorization': getAccessToken()}});

export const getSubmenuList = () => axios().get('/submenu');

export const getAboutPageData = () => axios().get('/about');
export const putAboutPageData = aboutData => axios().put('/about', {...aboutData}, {headers: {'Authorization': getAccessToken()}});

export const getConatctsPageData = () => axios().get('/contacts');
export const putConatctsData = conatctsData => axios().put('/contacts/' + conatctsData.id , {...conatctsData}, {headers: {'Authorization': getAccessToken()}});
export const deleteConatctsData = (id) => axios().delete('/contacts/' + id, {headers: {'Authorization': getAccessToken()}});
export const postConatctsData = conatctsData => axios().post('/contacts/', {...conatctsData}, {headers: {'Authorization': getAccessToken()}});

export const getConatctsServicePageData = () => axios().get('/contactsservices');
export const putConatctsServicePageData = contactsServiceData => axios().put('/contactsservices/' + contactsServiceData.id, {...contactsServiceData}, {headers: {'Authorization': getAccessToken()}});
export const deleteConatctsServicePageData = (id) => axios().delete('/contactsservices/' + id, {headers: {'Authorization': getAccessToken()}});
export const postConatctsServicePageData = conatctsData => axios().post('/contactsservices/', {...conatctsData}, {headers: {'Authorization': getAccessToken()}});

export const getFooterImagesData = () => axios().get('/footer-images');
export const deleteFooterImagesData = (id) => axios().delete('/footer-images/' + id, {headers: {'Authorization': getAccessToken()}});
export const putFooterImagesData = imageData => axios().put('/footer-images/' + imageData.id, {...imageData}, {headers: {'Authorization': getAccessToken()}});
export const postFooterImagesData = imageData => axios().post('/footer-images', {...imageData}, {headers: {'Authorization': getAccessToken()}});


export const getMenuData = () => axios().get('/menu');
export const deleteMenuData = (id) => axios().delete('/menu/' + id);
export const putMenuData = menuData => axios().put('/menu/' + menuData.id, {...menuData});
export const postMenuData = menuData => axios().post('/menu', {...menuData});

export const getSubmenuData = () => axios().get('/submenu');
export const deleteSubmenuData = (id) => axios().delete('/submenu/' + id);
export const putSubmenuData = submenuData => axios().put('/submenu/' + submenuData.id, {...submenuData});
export const postSubmenuData = submenuData => axios().post('/submenu', {...submenuData});

export const getDesignTypes = () => axios().get('/design-type');

export const getSubmenuHeadersData = () => axios().get('/submenu-headers');
export const getSubmenusForHeadersData = () => axios().get('/submenustoheaders');
export const deleteSubmenuHeadersData = (id) => axios().delete('/submenu-headers/' + id);
export const putSubmenuHeadersData = SubmenuHeaders => axios().put('/submenu-headers/' + SubmenuHeaders.id, {...SubmenuHeaders});
export const postSubmenuHeadersData = SubmenuHeaders => axios().post('/submenu-headers', {...SubmenuHeaders});

export const getSalonData = () => axios().get('/salon');
export const deleteSalonData = (id) => axios().delete('/salon/' + id);
export const putSalonData = salonData => axios().put('/salon/' + salonData.id, {...salonData});
export const postSalonData = salonData => axios().post('/salon', {...salonData});

export const getSalonTabsData = () => axios().get('/salon-tabs');
export const deleteSalonTabsData = (id) => axios().delete('/salon-tabs/' + id);
export const putSalonTabsData = salonTabsData => axios().put('/salon-tabs/' + salonTabsData.id, {...salonTabsData});
export const postSalonTabsData = salonTabsData => axios().post('/salon-tabs', {...salonTabsData});

export const getSalonBlocksData = () => axios().get('/salon-blocks');
export const getSalonBlockData = (id) => axios().get('/salon-blocks/' + id);
export const deleteSalonBlocksData = (id) => axios().delete('/salon-blocks/' + id);
export const putSalonBlocksData = salonBlocksData => axios().put('/salon-blocks/' + salonBlocksData.id, {...salonBlocksData});
export const postSalonBlocksData = salonBlocksData => axios().post('/salon-blocks', {...salonBlocksData});

export const getAutozagarData = () => axios().get('/autozagar');
export const findAutozagarData = (id) => axios().get('/autozagar/' + id);
export const deleteAutozagarData = (id) => axios().delete('/autozagar/' + id);
export const putAutozagarData = autozagarData => axios().put('/autozagar/' + autozagarData.id, {...autozagarData});
export const postAutozagarData = autozagarData => axios().post('/autozagar', {...autozagarData});

export const getAbonementData = () => axios().get('/abonement');
export const findAbonementData = (id) => axios().get('/abonement/' + id);
export const deleteAbonementData = (id) => axios().delete('/abonement/' + id);
export const putAbonementData = abonementData => axios().put('/abonement/' + abonementData.id, {...abonementData});
export const postAbonementData = abonementData => axios().post('/abonement', {...abonementData});

export const getZagarData = () => axios().get('/zagar');
export const findZagarData = (id) => axios().get('/zagar/' + id);
export const deleteZagarData = (id) => axios().delete('/zagar/' + id);
export const putZagarData = zagarData => axios().put('/zagar/' + zagarData.id, {...zagarData});
export const postZagarData = zagarData => axios().post('/zagar', {...zagarData});

export const getZagarTabsData = () => axios().get('/zagar-tabs');
export const deleteZagarTabsData = (id) => axios().delete('/zagar-tabs/' + id);
export const putZagarTabsData = zagarTabsData => axios().put('/zagar-tabs/' + zagarTabsData.id, {...zagarTabsData});
export const postZagarTabsData = zagarTabsData => axios().post('/zagar-tabs', {...zagarTabsData});

export const getZagarBlocksData = () => axios().get('/zagar-blocks');
export const findZagarBlocksData = (id) => axios().get('/zagar-blocks/' + id);
export const deleteZagarBlocksData = (id) => axios().delete('/zagar-blocks/' + id);
export const putZagarBlocksData = zagarBlocksData => axios().put('/zagar-blocks/' + zagarBlocksData.id, {...zagarBlocksData});
export const postZagarBlocksData = zagarBlocksData => axios().post('/zagar-blocks', {...zagarBlocksData});

export const getCosmeticPageData = () => axios().get('/cosmetics-page');
export const deleteCosmeticPageData = (id) => axios().delete('/cosmetics-page/' + id);
export const putCosmeticPageData = cosmeticPageData => axios().put('/cosmetics-page/' + cosmeticPageData.id, {...cosmeticPageData});
export const postCosmeticPageData = cosmeticPageData => axios().post('/cosmetics-page', {...cosmeticPageData});

export const getCosmeticRowData = () => axios().get('/cosmetics');
export const deleteCosmeticRowData = (id) => axios().delete('/cosmetics/' + id);
export const putCosmeticRowData = cosmeticRowData => axios().put('/cosmetics/' + cosmeticRowData.id, {...cosmeticRowData});
export const postCosmeticRowData = cosmeticRowData => axios().post('/cosmetics', {...cosmeticRowData});

export const getCosmeticProductData = () => axios().get('/cosmetic-products');
export const deleteCosmeticProductData = (id) => axios().delete('/cosmetic-products/' + id);
export const putCosmeticProductData = cosmeticProductData => axios().put('/cosmetic-products/' + cosmeticProductData.id, {...cosmeticProductData});
export const postCosmeticProductData = cosmeticProductData => axios().post('/cosmetic-products', {...cosmeticProductData});

export const getMetaPagesData = () => axios().get('/meta-data');
export const deleteMetaPagesData = (id) => axios().delete('/meta-data/' + id);
export const putMetaPagesData = MetaPagesData => axios().put('/meta-data/' + MetaPagesData.id, {...MetaPagesData});
export const postMetaPagesData = MetaPagesData => axios().post('/meta-data', {...MetaPagesData});

export const getMetaProductsData = () => axios().get('/meta-data-products');
export const deleteMetaProductsData = (id) => axios().delete('/meta-data/' + id);
export const putMetaProductsData = MetaProducts => axios().put('/meta-data/' + MetaProducts.id, {...MetaProducts});
export const postMetaProductsData = MetaProducts => axios().post('/meta-data', {...MetaProducts});


export const getLpgData = () => axios().get('/lpg');
export const findLpgData = (id) => axios().get('/lpg/' + id);
export const deleteLpgData = (id) => axios().delete('/lpg/' + id);
export const putLpgData = lpg => axios().put('/lpg/' + lpg.id, {...lpg});
export const postLpgData = lpg => axios().post('/lpg', {...lpg});

export const getContactsMeta = contactsMeta => axios().get('/contacts-meta');
export const putContactsMeta = contactsMeta => axios().put('/contacts-meta', {...contactsMeta});

export const getSocialNetworksData = () => axios().get('/social-networks');
export const deleteSocialNetworksData = (id) => axios().delete('/social-networks/' + id);
export const putSocialNetworksData = socialData => axios().put('/social-networks/' + socialData.id, {...socialData});
export const postSocialNetworksData = socialData => axios().post('/social-networks', {...socialData});

export const getHeaderPageData = () => axios().get('/header');
export const putHeaderPageData = headerData => axios().put('/header', {...headerData});

export const deleteFile = (id) => axios().delete('/uploads/' + id)
