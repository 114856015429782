import React, { PureComponent } from 'react';
import { Row, Col} from 'antd';
import DynamicPages from './DynamicPages'
import AbonementTable from './AbonementTable'

export default class Abonement extends PureComponent {

  render() {
    return <div>
        <Row>
            <Col span={4}><DynamicPages /></Col>
            <Col span={19}><AbonementTable /></Col>  
        </Row>
    </div>
  }
}