/* eslint-disable import/first */


import React from 'react';
import { Table, Popconfirm, Form, Button, message} from 'antd';
import { Link } from 'react-router-dom';

const EditableContext = React.createContext();

import { 
    getLpgData,
    getSubmenuHeadersData,
    deleteLpgData    
} from '../api';


class LpgTable extends React.Component {
    constructor(props) {

        super(props)
        this.state = {
             data: [],
             submenuHeaders: []
        };
        this.columns = [
            {
                title: 'Submenu Headers',
                dataIndex: 'submenu_headers_id',
                width: '20%', 
                editable: true,
                render: (text,record) => {
                    let submenuHeadersText = '' 
                    this.state.submenuHeaders.forEach(function(s){
                        if (record.submenu_headers_id == s.id) 
                            submenuHeadersText = s.title + s.id
                    })
                    return (
                        <p>{submenuHeadersText}</p>
                    ) 
                }
            },
            {
                title: 'operation',
                dataIndex: 'operation',
                render: (text, record) => {
                    const url = '/lpg/' + record.id
                    return (
                        <Link to={url} >Edit</Link>
                    )
                }
            },
            {
                title: 'delete',
                dataIndex: 'delete',
                render: (text, record) =>
                  this.state.data.length >= 1 ? (
                    <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record.id)}>
                      <a href="javascript:;">Delete</a>
                    </Popconfirm>
                  ) : null,
              },
        ];


    }


    async componentDidMount() {
        const { data } = await getLpgData();
        const submenuHeaders  = await getSubmenuHeadersData();
        this.setState({
            data: data.data,
            submenuHeaders: submenuHeaders.data.data,
        });

        this.props.form.setFieldsValue(this.state)
    }

    handleDelete = async id => {
        const data = this.state.data;
        try {
            const requestData = await deleteLpgData(id)
            this.setState({ data: data.filter(item => item.id !== id) });
            message.success('Success')
        } catch (err) {
            message.error('Something Went Wrong ' + err.response.status)
        }
    };


    render() {
        const columns = this.columns.map(col => {

            let inputType = 'text'
            return {
                ...col,
                onCell: record => ({
                record,
                inputType: inputType,
                dataIndex: col.dataIndex,
                title: col.title,
                }),
            };
        });

        return (
            <div>
                <Button  type="primary" style={{ marginBottom: 16 }}>
                    <Link to={'/lpg/new'} >Add a row</Link>
                </Button>
                <EditableContext.Provider value={this.props.form}>
                    <Table
                    bordered
                    dataSource={this.state.data}
                    columns={columns}
                    rowClassName="editable-row"
                    pagination={false}
                    />
                </EditableContext.Provider>
            </div>
            
        );
    }

}

export default Form.create({ name: 'lpgTable' })(LpgTable);

