import React, { PureComponent } from 'react';
import { Row, Col} from 'antd';
import DynamicPages from './DynamicPages'
import AutozagarEditForm from './AutozagarEditForm'

export default class AutozagarEdit extends PureComponent {
  state = {};

  render() {
    return <div>
        <Row>
            <Col span={4}><DynamicPages /></Col>
            <Col span={19}><AutozagarEditForm {...this.props} /></Col>  
        </Row>
    </div>
  }
}