/* eslint-disable import/first */


import React from 'react';
import { Table, Input, Popconfirm, Form, Button, Select, message } from 'antd';
const EditableContext = React.createContext();

import { 
    getSalonData,
    deleteSalonData,
    putSalonData,
    postSalonData,
    getSubmenuHeadersData
    
} from '../api';

class EditableCell extends React.Component {
    handleChange(value) {
    }
    
  getInput = () => {
    if (this.props.inputType === 'select') {
        let selectValues = ''
            selectValues = this.props.submenuHeaders.map(d => {
                return  <Select.Option value={d.id} >{d.title}</Select.Option>
            })
      return   <Select
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    onChange={this.handleChange}
                    >
                    {selectValues}
                </Select>;
    }
    return <Input />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      submenuHeaders,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ],

            initialValue: record[dataIndex]
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}


class SalonTable extends React.Component {
    constructor(props) {

        super(props)
        this.state = {
             data: [], 
             submenuHeaders: [], 
             editingKey: '',
             isNewRow: false };
        this.columns = [
            {
                title: 'name',
                dataIndex: 'name',
                width: '20%',
                editable: true,
            },
            {
                title: 'SubMenu Headers',
                dataIndex: 'submenu_headers_id',
                width: '20%',
                editable: true,
                render: (text,record) => {
                    let submenuHeadersText = '' 
                    this.state.submenuHeaders.forEach(function(s){
                        if (record.submenu_headers_id == s.id) 
                        submenuHeadersText = s.title
                    })
                    return (
                        <p>{submenuHeadersText}</p>
                    ) 
                }
            },
            {
                title: 'operation',
                dataIndex: 'operation',
                render: (text, record) => {
                const { editingKey } = this.state;
                const editable = this.isEditing(record);
                return editable ? (
                    <span>
                    <EditableContext.Consumer>
                        {form => (
                        <a
                            href="javascript:;"
                            onClick={() => this.save(form, record.id)}
                            style={{ marginRight: 8 }}
                        >
                            Save
                        </a>
                        )}
                    </EditableContext.Consumer>
                    <Popconfirm title="Sure to cancel?" onConfirm={() => this.cancel(record.id)}>
                        <a>Cancel</a>
                    </Popconfirm>
                    </span>
                ) : (
                    <a disabled={editingKey !== ''} onClick={() => this.edit(record.id)}>
                    Edit
                    </a>
                );
                },
            },
            {
                title: 'delete',
                dataIndex: 'delete',
                render: (text, record) =>
                  this.state.data.length >= 1 ? (
                    <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record.id)}>
                      <a href="javascript:;">Delete</a>
                    </Popconfirm>
                  ) : null,
              },
        ];


    }


    async componentDidMount() {
        const { data } = await getSalonData();
        const submenuHeaders  = await getSubmenuHeadersData();

        this.setState({
            data: data.data,
            submenuHeaders: submenuHeaders.data.data,
        });

        this.props.form.setFieldsValue(this.state)
    }

    isEditing = record => record.id === this.state.editingKey;

    cancel = (id) => {
        if(this.state.isNewRow) {
            const data = this.state.data;
            this.setState({ data: data.filter(item => item.id !== id) });
        }
        this.setState({ editingKey: '' ,isNewRow: false});
    };

    handleDelete = async  id => {
        const data = this.state.data;
        if(!this.state.isNewRow) {
            try {
                const requestData = await deleteSalonData(id)
                this.setState({ data: data.filter(item => item.id !== id) });
                message.success('Success')
             } catch (err) {
                 message.error('Something Went Wrong ' + err.response.status)
             }
            
        }
    };

    handleAdd = () => {
        if (!this.state.isNewRow) {
            const  data = this.state.data;
            const newData = {
                id: data[data.length - 1] !== undefined ? data[data.length - 1].id + 1 : 1,
                name: '',
                submenu_headers_id: ''
                
            };
            this.setState({
                data: [...data, newData],
                editingKey: newData.id,
                isNewRow: true
            });
        }
    };


    save(form, key) {
        form.validateFields( async (error, row) => {
        if (error) {
            return;
        }
        const newData = [...this.state.data];
        const index = newData.findIndex(item => key === item.id);
        if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, {
            ...item,
            ...row,
            });

            if(this.state.isNewRow) {
                try {
                    const requestData = await postSalonData(newData[index])
                    this.setState({ data: newData, editingKey: '' , isNewRow: false});
                    message.success('Success')
                 } catch (err) {
                     message.error('Something Went Wrong ' + err.response.status)
                 } 
            } else {
                try {putSalonData(newData[index])
                    this.setState({ data: newData, editingKey: '' , isNewRow: false});
                    message.success('Success')
                 } catch (err) {
                     message.error('Something Went Wrong ' + err.response.status)
                 } 
                
            }

            
        } else {

            if(this.state.isNewRow) {
                try {
                    postSalonData(newData[index])
                    newData.push(row);
                    this.setState({ data: newData, editingKey: '', isNewRow: false });
                            message.success('Success')
                 } catch (err) {
                     message.error('Something Went Wrong ' + err.response.status)
                 }
                
            } else {
                try {
                    putSalonData(newData[index])
                    newData.push(row);
                    this.setState({ data: newData, editingKey: '', isNewRow: false });
                            message.success('Success')
                 } catch (err) {
                     message.error('Something Went Wrong ' + err.response.status)
                 }
                
            }
            

        }
        });
    }

    edit(key) {

        this.setState({ editingKey: key });
    }

    render() {
        const components = {
        body: {
            cell: EditableCell,
        },
        };
        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            let inputType = 'text'
            if(col.dataIndex === 'submenu_headers_id') {
                inputType = 'select'
            }
            return {
                ...col,
                onCell: record => ({
                record,
                inputType: inputType,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: this.isEditing(record),
                submenuHeaders: this.state.submenuHeaders,
                }),
            };
        });

        return (
            <div>
                <Button onClick={this.handleAdd} type="primary" style={{ marginBottom: 16 }}>
                Add a row
                </Button>
                <EditableContext.Provider value={this.props.form}>
                    <Table
                    components={components}
                    bordered
                    dataSource={this.state.data}
                    columns={columns}
                    rowClassName="editable-row"
                    pagination={false}
                    />
                </EditableContext.Provider>
            </div>
            
        );
    }

}


export default Form.create({ name: 'SalonTable' })(SalonTable);

