import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Menu } from 'antd';

import { emptyState } from '../services/localStorage';
import { signOut } from '../api'

const Header = (props) => {

  const handleLogout = () => {
    signOut();
    emptyState('accessToken');
    props.logout();
    props.history.push('/sign-in')
  }

  return (
    <Fragment>
      <div>
      <Menu mode='horizontal' style={{ textAlign: 'right' }}>
        <Menu.Item onClick={() => props.history.push('/')}>Static Pages</Menu.Item>
        <Menu.Item onClick={() => props.history.push('/dynamic-pages')}>Dynamic Pages</Menu.Item>
        <Menu.Item onClick={handleLogout}>Logout</Menu.Item>
      </Menu>
      </div>
    </Fragment>
  )
}

export default withRouter(Header);