import React, { PureComponent } from 'react';
import { Row, Col} from 'antd';
import DynamicPages from './DynamicPages'
import LpgTable from './LpgTable'

export default class Lpg extends PureComponent {

  render() {
    return <div>
        <Row>
            <Col span={4}><DynamicPages /></Col>
            <Col span={19}><LpgTable /></Col>  
        </Row>
    </div>
  }
}