export const loadState = (key) => localStorage.getItem(key) || undefined;

export const saveState = (key, value) => {
  if (!!value) {
    localStorage.setItem(key, value);
  }
};

export const emptyState = (key) => {
  localStorage.removeItem(key);
};

export const getAccessToken = () => loadState('accessToken') || '';
