import React, { PureComponent } from 'react';
import { Menu, Row, Col} from 'antd';
import { Link } from 'react-router-dom';
import { getAboutPageData } from '../api';
import StaticMenus from './StaticPages'
import AboutForm from './aboutForm'

export default class About extends PureComponent {
  state = {};

  async componentDidMount() {
    const { data } = await getAboutPageData();
    this.setState({
      AboutForm: data
    });
  }

  render() {
    return <div>
        <Row>
            <Col span={8}><StaticMenus /></Col>
            <Col span={8}><AboutForm /></Col>  
        </Row>
    </div>
  }
}