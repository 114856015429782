/* eslint-disable import/first */


import React from 'react';
import { Table, Input, Popconfirm, Form, Button, Upload, Icon } from 'antd';
import { getAccessToken } from '../services/localStorage';

const EditableContext = React.createContext();
import { 
    getSocialNetworksData,
    deleteSocialNetworksData,
    putSocialNetworksData,
    postSocialNetworksData
} from '../api';

class EditableCell extends React.Component {

    normFile = e => {
        console.log('Upload event:', e);
        if(e.file.status == 'done') {
            return e.file.response.id;
        }
        
      };

    getInput = () => {
        const  uploadProps = {
            action: process.env.REACT_APP_ROOT_API + '/uploads/',
            listType: 'picture',
            name: 'uri',
            headers: {
                Authorization: getAccessToken(),
            },
        };
        if(this.props.inputType === 'url') {
            console.log(this.props)
            return <Input  style={{ width: '200px' }}    />;
        }
        return  <Upload {...uploadProps}>
                    <Button>
                        <Icon type="upload" /> Upload
                    </Button>
                </Upload>;
    };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                },
              ],
              initialValue: record[dataIndex],
            //   valuePropName: 'uri',
            getValueFromEvent: inputType === 'image' ?  this.normFile : ''
        })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}


class SocialNetworksTable extends React.Component {
    constructor(props) {

        super(props)
        this.state = {
             data: [], 
             editingKey: '',
             isNewRow: false };
        this.columns = [
            {
                title: 'image',
                dataIndex: 'image',
                width: '20%',
                editable: true,
            },
            {
                title: 'url',
                dataIndex: 'url',
                width: '20%',
                editable: true,
            },
            {
                title: 'operation',
                dataIndex: 'operation',
                render: (text, record) => {
                const { editingKey } = this.state;
                const editable = this.isEditing(record);
                return editable ? (
                    <span>
                    <EditableContext.Consumer>
                        {form => (
                        <a
                            href="javascript:;"
                            onClick={() => this.save(form, record.id)}
                            style={{ marginRight: 8 }}
                        >
                            Save
                        </a>
                        )}
                    </EditableContext.Consumer>
                    <Popconfirm title="Sure to cancel?" onConfirm={() => this.cancel(record.id)}>
                        <a>Cancel</a>
                    </Popconfirm>
                    </span>
                ) : (
                    <a disabled={editingKey !== ''} onClick={() => this.edit(record.id)}>
                    Edit
                    </a>
                );
                },
            },
            {
                title: 'delete',
                dataIndex: 'delete',
                render: (text, record) =>
                  this.state.data.length >= 1 ? (
                    <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record.id)}>
                      <a href="javascript:;">Delete</a>
                    </Popconfirm>
                  ) : null,
              },
        ];


    }


    async componentDidMount() {
        const { data } = await getSocialNetworksData();
        this.setState({data: data.data});

        this.props.form.setFieldsValue(this.state)
    }

    isEditing = record => record.id === this.state.editingKey;

    cancel = (id) => {
        if(this.state.isNewRow) {
            const data = this.state.data;
            this.setState({ data: data.filter(item => item.id !== id) });
        }
        this.setState({ editingKey: '' ,isNewRow: false});
    };

    handleDelete = id => {
        const data = this.state.data;
        this.setState({ data: data.filter(item => item.id !== id) });
        if(!this.state.isNewRow) {
            deleteSocialNetworksData(id)
        }
    };

    handleAdd = () => {
        if (!this.state.isNewRow) {
            const  data = this.state.data;
            let id = 1
            if(data.length > 0) {
                id = data[data.length - 1] !== undefined ? data[data.length - 1].id + 1 : 1
            }
            const newData = {
                id: id,
                image: ''
            };
            this.setState({
                data: [...data, newData],
                editingKey: newData.id,
                isNewRow: true
            });
        }
    };


    save(form, key) {
        form.validateFields((error, row) => {
        if (error) {
            return;
        }
        const newData = [...this.state.data];
        const index = newData.findIndex(item => key === item.id);
        if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, {
            ...item,
            ...row,
            });
            if(this.state.isNewRow) {
                postSocialNetworksData(newData[index])
            } else {
                putSocialNetworksData(newData[index])
            }

            this.setState({ data: newData, editingKey: '' , isNewRow: false});
            
        } else {
            if(this.state.isNewRow) {                
                postSocialNetworksData(newData[index])
            } else {
                putSocialNetworksData(newData[index])
            }
            
            newData.push(row);
            this.setState({ data: newData, editingKey: '', isNewRow: false });

        }
        });
    }

    edit(key) {

        this.setState({ editingKey: key });
    }

    render() {
        const components = {
        body: {
            cell: EditableCell,
        },
        };
        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                record,
                inputType: col.dataIndex === 'url' ? 'url' : 'image',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: this.isEditing(record),
                }),
            };
        });

        return (
            <div>
                <Button onClick={this.handleAdd} type="primary" style={{ marginBottom: 16 }}>
                Add a row
                </Button>
                <EditableContext.Provider value={this.props.form}>
                    <Table
                    components={components}
                    bordered
                    dataSource={this.state.data}
                    columns={columns}
                    rowClassName="editable-row"
                    pagination={false}
                    />
                </EditableContext.Provider>
            </div>
            
        );
    }

}


export default Form.create({ name: 'socialNetworksTable' })(SocialNetworksTable);

