
import React from 'react';
import { Form, Icon, Input, Button, Upload, Select, message} from 'antd';
import { 
    findZagarBlocksData,
    getZagarTabsData,
    putZagarBlocksData,
    postZagarBlocksData,
    deleteFile

} from '../api';
import { getAccessToken } from '../services/localStorage';

import ReactQuill from 'react-quill'

class ZagarBlocks extends React.Component {
    state = {
        editingKey: '',
        isNewRow: false ,
        zagarTabsList: []
    }
    normFile = e => {
        if(e.file.status == 'done') {
            return e.file.response.id;
        }
        
    };
    async componentDidMount() {
        const id = this.props.match.params.id
        const zagarTabsList = await getZagarTabsData();
        if (id == 'new') {
            const emptyData = {
                title: '',
                text: '',
                price_text: '',
                image_one: "",
                image_two: ""
            }
            this.setState({
                ...emptyData,
                isNewRow: true,
                zagarTabsList: zagarTabsList.data.data
            });
        } else {
            const { data } = await findZagarBlocksData(id);
            const zagarTabsList = await getZagarTabsData();
            this.setState({
                ...data,
                zagarTabsList: zagarTabsList.data.data
            });
        }
        
    }

      handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if(values.image_one === undefined) {
                values.image_one = ""
            }
            if(values.image_two === undefined) {
                values.image_two = ""
            }
          if (!err) {
            try {
                if(this.state.isNewRow) {
                    const postData = await postZagarBlocksData(values)
                    message.success('success ' + postData.status);
                }  else {
                    
                    values.id = this.state.id
                    const postData = await putZagarBlocksData(values)
                    message.success('success ' + postData.status);
                }
                

            } catch (error) {
                message.error('error', error.status);
            }
          }
        });
      }

      handleDeleteImage = async ( param ) => {
        console.log(this.state)
        if(param == '') {
            message.info('Already empty')
            return;
        }
            
        if (param === this.state.image_one) {
            try {
                const deleteData = await deleteFile(param)
                message.success('success ' + deleteData.status);
            } catch (error) {
                message.error('error', error.status);
            }
            this.setState({image_one: ''})

        }
        if (param === this.state.image_two) {
            try {
                const deleteData = await deleteFile(param)
                message.success('success ' + deleteData.status);
            } catch (error) {
                message.error('error', error.status);
            }
            this.setState({image_two: ''})
        }
      }


    render () {

        const uploadProps = {
            action: process.env.REACT_APP_ROOT_API + '/uploads/',
            listType: 'picture',
            name: 'uri',
            headers: {
                Authorization: getAccessToken(),
            },
            onRemove: async file => {
                try {
                   const deleteData = await deleteFile(file.name)
                   message.success('success ' + deleteData.status);
               } catch (error) {
                   message.error('error', error.status);
               }
           },
        };

        let selectValues = ''
        selectValues = this.state.zagarTabsList.map(s => {
            return  <Select.Option value={s.id} >{s.name}</Select.Option>
        })

        const { getFieldDecorator } = this.props.form;

        return (
             
            <Form onSubmit={this.handleSubmit}>
                
                <Form.Item label="Title">
                    {getFieldDecorator('title', {
                        rules: [{
                            required: true, message: 'Please input your title',
                        }],
                        initialValue: this.state.title
                    }, )(
                        <Input />
                    )}
                </Form.Item>

                <Form.Item label="Head Text">
                    {getFieldDecorator('text', {
                        rules: [{
                            required: true, message: 'Please input your Head Text',
                        }],
                        initialValue: this.state.text
                    })(
                        <ReactQuill />
                    )}
                </Form.Item>

                <Form.Item label="Footer Text">
                    {getFieldDecorator('price_text', {
                        rules: [{
                            required: true, message: 'Please input your Footer Text',
                        }],
                        initialValue: this.state.price_text
                    })(
                        <ReactQuill />
                    )}
                </Form.Item>
                <Form.Item label="Zagar Tabs">
                            {getFieldDecorator('zagar_tabs_id', {
                            rules: [{
                                required: true, message: 'Please Select',
                            }],
                                initialValue: this.state.zagar_tabs_id ,
                                
                            })(
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Please select"
                                    >
                                    {selectValues}
                                </Select>
                            )}
                </Form.Item>
                <Form.Item label="Image one" extra="Image one">
                            {getFieldDecorator('image_one', {
                                valuePropName: 'uri',
                                getValueFromEvent: this.normFile,
                                initialValue: this.state.image_one ,
                            })(
                                <Upload {...uploadProps}>
                                    <Button>
                                        <Icon type="upload" /> Upload
                                    </Button>
                                </Upload>
                            )}
                </Form.Item>

                <Button onClick={() => this.handleDeleteImage(this.state.image_one)} type="primary" style={{ marginLeft: 16 }}>
                                            Delete Image One
                </Button>

                <Form.Item label="Image two" extra="Image two">
                            {getFieldDecorator('image_two', {
                                valuePropName: 'uri',
                                getValueFromEvent: this.normFile,
                                initialValue: this.state.image_two ,
                            })(
                                <Upload {...uploadProps}>
                                    <Button>
                                        <Icon type="upload" /> Upload
                                    </Button>
                                </Upload>
                            )}
                </Form.Item>

                <Button onClick={() => this.handleDeleteImage(this.state.image_two)} type="primary" style={{ marginLeft: 16 }}>
                    Delete Image Two
                </Button>

                <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
                </Form.Item>

            </Form>
        )
    }
}



export default Form.create({ name: 'zagarBlocks' })(ZagarBlocks);