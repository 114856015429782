
import React from 'react';
import { Form, Icon, Input, Button, Upload, Select, message} from 'antd';
import { 
    findAbonementData,
    getSubmenuHeadersData,
    putAbonementData,
    postAbonementData

} from '../api';

import ReactQuill from 'react-quill'

class AutozagarEditForm extends React.Component {
    state = {
        isNewRow: false ,
        submenuHeaders: []
    }


    normFile = e => {
        if(e.file.status == 'done') {
            return e.file.response.id;
        }
        
    };
    async componentDidMount() {
        const id = this.props.match.params.id
        const submenuHeaders = await getSubmenuHeadersData();
        if (id == 'new') {
            const emptyData = {
                title_one: '',
                title_two: '',
                title_three: '',
                text_one: '',
                text_two: '',
                text_three: '',
                c_title_one: '',
                c_title_two: '',
                c_title_three: '',
                c_title_four: '',
                c_text_one: '',
                c_text_two: '',
                c_text_three: '',
                c_text_four: '',
                c_footer_text: '',
            }
            this.setState({
                ...emptyData,
                isNewRow: true,
                submenuHeaders: submenuHeaders.data.data
            });
        } else {
            const { data } = await findAbonementData(id);
            const submenuHeaders = await getSubmenuHeadersData();
            this.setState({
                ...data,
                submenuHeaders: submenuHeaders.data.data
            });
        }
        
    }

      handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            
          if (!err) {
            try {
                if(this.state.isNewRow) {
                    const postData = await postAbonementData(values)
                    message.success('success ' + postData.status);
                    this.props.history.push('/abonement')

                }  else {
                    
                    values.id = this.state.id
                    const postData = await putAbonementData(values)
                    message.success('success ' + postData.status);
                    this.props.history.push('/abonement')
                }
                

            } catch (error) {
                message.error('error', error.response.status);
            }
          }
        });
      }


    render () {

        let selectValues = ''
        selectValues = this.state.submenuHeaders.map(s => {
            return  <Select.Option value={s.id} >{s.title + ' ' + s.id}</Select.Option>
        })

        const { getFieldDecorator } = this.props.form;

        return (
             
            <Form onSubmit={this.handleSubmit}>
                <Form.Item label="Submenu Headers">
                            {getFieldDecorator('submenu_headers_id', {
                            rules: [{
                                required: true, message: 'Please Select',
                            }],
                                initialValue: this.state.submenu_headers_id ,
                                
                            })(
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Please select"
                                    >
                                    {selectValues}
                                </Select>
                            )}
                </Form.Item>
                
                <Form.Item label="Title One">
                    {getFieldDecorator('title_one', {
                        rules: [{
                            required: true, message: 'Please input your title',
                        }],
                        initialValue: this.state.title_one
                    }, )(
                        <Input />
                    )}
                </Form.Item>

                <Form.Item label="Title Two">
                    {getFieldDecorator('title_two', {
                        rules: [{
                            required: true, message: 'Please input your Head Text',
                        }],
                        initialValue: this.state.title_two
                    })(
                        <Input />
                    )}
                </Form.Item>

                <Form.Item label="Title Three">
                    {getFieldDecorator('title_three', {
                        rules: [{
                            required: true, message: 'Please input your Head Text',
                        }],
                        initialValue: this.state.title_three
                    })(
                        <Input />
                    )}
                </Form.Item>
                
                <Form.Item label="Text One">
                    {getFieldDecorator('text_one', {
                        rules: [{
                            required: true, message: 'Please input your Head Text',
                        }],
                        initialValue: this.state.text_one
                    })(
                        <ReactQuill />
                    )}
                </Form.Item>

                <Form.Item label="Text Two">
                    {getFieldDecorator('text_two', {
                        rules: [{
                            required: true, message: 'Please input your Head Text',
                        }],
                        initialValue: this.state.text_two
                    })(
                        <ReactQuill />
                    )}
                </Form.Item>

                <Form.Item label="Text Three">
                    {getFieldDecorator('text_three', {
                        rules: [{
                            required: true, message: 'Please input your Head Text',
                        }],
                        initialValue: this.state.text_three
                    })(
                        <ReactQuill />
                    )}
                </Form.Item>

                <Form.Item label="Centeral Title One">
                    {getFieldDecorator('c_title_one', {
                        rules: [{
                            required: true, message: 'Please input your Head Text',
                        }],
                        initialValue: this.state.c_title_one
                    })(
                        <Input />
                    )}
                </Form.Item>
                <Form.Item label="Centeral Title two">
                    {getFieldDecorator('c_title_two', {
                        rules: [{
                            required: true, message: 'Please input your Head Text',
                        }],
                        initialValue: this.state.c_title_two
                    })(
                        <Input />
                    )}
                </Form.Item>
                <Form.Item label="Centeral Title three">
                    {getFieldDecorator('c_title_three', {
                        rules: [{
                            required: true, message: 'Please input your Head Text',
                        }],
                        initialValue: this.state.c_title_three
                    })(
                        <Input />
                    )}
                </Form.Item>
                <Form.Item label="Centeral Title four">
                    {getFieldDecorator('c_title_four', {
                        rules: [{
                            required: true, message: 'Please input your Head Text',
                        }],
                        initialValue: this.state.c_title_four
                    })(
                        <Input />
                    )}
                </Form.Item>


                <Form.Item label="Centeral Text One">
                    {getFieldDecorator('c_text_one', {
                        rules: [{
                            required: true, message: 'Please input your Footer Text',
                        }],
                        initialValue: this.state.c_text_one
                    })(
                        <ReactQuill />
                    )}
                </Form.Item>
                <Form.Item label="Centeral Text two">
                    {getFieldDecorator('c_text_two', {
                        rules: [{
                            required: true, message: 'Please input your Footer Text',
                        }],
                        initialValue: this.state.c_text_two
                    })(
                        <ReactQuill />
                    )}
                </Form.Item>
                <Form.Item label="Centeral Text three">
                    {getFieldDecorator('c_text_three', {
                        rules: [{
                            required: true, message: 'Please input your Footer Text',
                        }],
                        initialValue: this.state.c_text_three
                    })(
                        <ReactQuill />
                    )}
                </Form.Item>
                
                <Form.Item label="Centeral Text four">
                    {getFieldDecorator('c_text_four', {
                        rules: [{
                            required: true, message: 'Please input your Footer Text',
                        }],
                        initialValue: this.state.c_text_four
                    })(
                        <ReactQuill />
                    )}
                </Form.Item>

                <Form.Item label="Centeral Footer text">
                    {getFieldDecorator('c_footer_text', {
                        rules: [{
                            required: true, message: 'Please input your Footer Text',
                        }],
                        initialValue: this.state.c_footer_text
                    })(
                        <ReactQuill />
                    )}
                </Form.Item>

                <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
                </Form.Item>

            </Form>
        )
    }
}



export default Form.create({ name: 'autozagarEditFormEdit' })(AutozagarEditForm);