import React, { Fragment } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Header from './Header';
import SignIn from './SignIn';
import StaticPages from './StaticPages';
import DynamicPages from './DynamicPages';
import Home from './Home';
import About from './About';
import Contacts from './Contacts';
import ContactsService from './ContactsService';
import FooterImages from './FooterImages';
import Menus from './Menu';
import Submenu from './Submenu';
import SubmenuHeaders from './SubmenuHeaders';
import Salon from './Salon';
import SalonTabs from './SalonTabs';
import SalonBlocks from './SalonBlocks';
import SalonBlocksEdit from './SalonBlocksEdit';
import Autozagar from './Autozagar';
import AutozagarEdit from './AutozagarEdit';
import Abonement from './Abonement';
import AbonementEdit from './AbonementEdit';

import Zagar from './Zagar';
import ZagarEdit from './ZagarEdit';
import ZagarTabs from './ZagarTabs';
import ZagarBlocks from './ZagarBlocks';
import ZagarBlocksEdit from './ZagarBlocksEdit';
import CosmeticPage from './CosmeticPage';
import CosmeticRow from './CosmeticRow';
import CosmeticProduct from './CosmeticProduct';
import MetaPages from './MetaPages';
import MetaContacts from './MetaContacts';
import SocialNetworks from './SocialNetworks';
import HeaderPage from './HeaderPage';

import Lpg from './Lpg';
import LpgEdit from './LpgEdit';


import LeftMenu from './LeftMenu'

export default ({isAuthenticated, login, logout}) => {
  return (
    <BrowserRouter>
      <Switch>
        {
          isAuthenticated 
            ? <Fragment>
                <Header logout={logout} />
                <Route exact path='/dynamic-pages' component={DynamicPages} />
                <Route exact path='/home' component={Home} />
                <Route exact path='/about' component={About} />
                <Route exact path='/contacts' component={Contacts} />
                <Route exact path='/contacts-service' component={ContactsService} />
                <Route exact path='/footer-images' component={FooterImages} />
                <Route exact path='/' component={StaticPages} />
                <Route exact path='/menu' component={Menus} />
                <Route exact path='/submenu' component={Submenu} />
                <Route exact path='/submenu-header' component={SubmenuHeaders} />
                <Route exact path='/salon' component={Salon} />
                <Route exact path='/salon-tab' component={SalonTabs} />
                <Route exact path='/salon-block' component={SalonBlocks} />
                <Route path='/salon-block/:id' component={SalonBlocksEdit} />
                <Route exact path='/autozagar' component={Autozagar} />
                <Route path='/autozagar/:id' component={AutozagarEdit} />
                <Route exact path='/abonement' component={Abonement} />
                <Route path='/abonement/:id' component={AbonementEdit} />
                <Route exact path='/zagar' component={Zagar} />
                <Route exact path='/zagar/:id' component={ZagarEdit} />
                <Route exact path='/zagar-tab' component={ZagarTabs} />
                <Route exact path='/zagar-block' component={ZagarBlocks} />
                <Route path='/zagar-block/:id' component={ZagarBlocksEdit} />
                <Route path='/cosmetic-page' component={CosmeticPage} />
                <Route path='/cosmetic-row' component={CosmeticRow} />
                <Route path='/cosmetic-product' component={CosmeticProduct} />
                <Route path='/meta-pages' component={MetaPages} />
                <Route exact path='/lpg' component={Lpg} />
                <Route path='/lpg/:id' component={LpgEdit} />
                <Route path='/meta-contacts' component={MetaContacts} />
                <Route path='/social-networks' component={SocialNetworks} />
                <Route path='/header' component={HeaderPage} />
            </Fragment>
            : <Fragment>
              <Route exact path='/sign-in' render={(props) => <SignIn login={login} {...props} />}  />
              <Route exact path='/' render={(props) => <SignIn login={login} {...props} />}  />
            </Fragment>
        }
      </Switch>
    </BrowserRouter>
  )
}
