import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';

export default () => {
  return (
    <Menu style={{ width: 200 }}>
      <Menu.Item><Link to='/menu'>Menu</Link></Menu.Item>
      <Menu.Item><Link to='/submenu'>Submenu</Link></Menu.Item>
      <Menu.Item><Link to='/submenu-header'>Submenu Header</Link></Menu.Item>
      <Menu.Item><Link to='/abonement'>Abonement</Link></Menu.Item>
      <Menu.Item><Link to='/autozagar'>Autozagar</Link></Menu.Item>
      <Menu.Item><Link to='/lpg'>LPG MAssage</Link></Menu.Item>
      <Menu.Item><Link to='/zagar'>Zagar Page</Link></Menu.Item>
      <Menu.Item><Link to='/zagar-tab'>Zagar Tab</Link></Menu.Item>
      <Menu.Item><Link to='/zagar-block'>Zagar Block</Link></Menu.Item>
      <Menu.Item><Link to='/salon'>Salon Page</Link></Menu.Item>
      <Menu.Item><Link to='/salon-tab'>Salon Tab</Link></Menu.Item>
      <Menu.Item><Link to='/salon-block'>Salon Block</Link></Menu.Item>
      <Menu.Item><Link to='/cosmetic-page'>Cosmetic Page</Link></Menu.Item>
      <Menu.Item><Link to='/cosmetic-row'>Cosmetic Row</Link></Menu.Item>
      <Menu.Item><Link to='/cosmetic-product'>Cosmetic Product</Link></Menu.Item>
      <Menu.Item><Link to='/meta-pages'>Meta Data For Pages</Link></Menu.Item>
      <Menu.Item><Link to='/meta-contacts'>Meta Data For Contacts</Link></Menu.Item>
    </Menu>
  )
}