import React, { PureComponent } from 'react';
import { Row, Col} from 'antd';
import DynamicPages from './DynamicPages'
import MetaContactsForm from './MetaContactsForm'

export default class MetaContacts extends PureComponent {

  render() {
    return <div>
    <Row>
      <Col span={4}><DynamicPages /></Col>
      <Col span={19}><MetaContactsForm /></Col>  
    </Row>
    </div>
  }
}