import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';

export default () => {
  return (
    <Menu style={{ width: 200 }}>
      <Menu.Item><Link to='/home'>Homepage</Link></Menu.Item>
      <Menu.Item><Link to='/about'>About Us</Link></Menu.Item>
      <Menu.Item><Link to='/contacts'>Contact Us</Link></Menu.Item>
      <Menu.Item><Link to='/contacts-service'>Contact Services</Link></Menu.Item>
      <Menu.Item><Link to='/footer-images'>Footer Images</Link></Menu.Item>
      <Menu.Item><Link to='/social-networks'>Social Networks</Link></Menu.Item>
      <Menu.Item><Link to='/header'>Header</Link></Menu.Item>
    </Menu>
  )
}