import React, { PureComponent } from 'react';
import { Row, Col} from 'antd';
import StaticMenus from './StaticPages'
import HomeForm from './homeForm'

export default class Home extends PureComponent {

  render() {
    return <div>
    <Row>
      <Col span={4}><StaticMenus /></Col>
      <Col span={19}><HomeForm /></Col>  
    </Row>

    </div>
  }
}