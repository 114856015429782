import React, { PureComponent } from 'react';
import { Row, Col} from 'antd';
import DynamicPages from './DynamicPages'
import CosmeticRowTable from './CosmeticRowTable'

export default class CosmeticRow extends PureComponent {

  render() {
    return <div>
        <Row>
            <Col span={4}><DynamicPages /></Col>
            <Col span={19}><CosmeticRowTable  /></Col>  
        </Row>
    </div>
  }
}