
import React from 'react';
import { Form, Icon, Input, Button, Upload, Select , Option, Tabs, message} from 'antd';
import { getHomePageData, getSubmenuList, putHomePageData, getZagarTabsData, getSalonTabsData } from '../api';
import { getAccessToken } from '../services/localStorage';
import ReactQuill from 'react-quill'
const TabPane = Tabs.TabPane;



class HomeForm extends React.Component {

    state = {
        submenuList: [],
        images: {
            image_one: {
                submenuTabs: []
            },
            image_two: {
                submenuTabs: []
            },
            image_three: {
                submenuTabs: []
            },
            image_four: {
                submenuTabs: []
            },
            image_five: {
                submenuTabs: []
            },
            image_six: {
                submenuTabs: []
            },
        },
        content_product: {
            images: [],
        },
        meta: [],

    }

    normFile = e => {
        console.log('Upload event:', e);

        if(e.file.status == 'done') {
            return e.file.response.id;
        }
        
      };

    async componentDidMount() {
        const { data } = await getHomePageData();
        const submenuList  = await getSubmenuList();
   
        const salonTabs = await getSalonTabsData();
        const zagarTabs = await getZagarTabsData();
        Object.values(data.images).map((i) => {
            i.submenuTabs = []
            submenuList.data.data.map(s => {
                console.log(i.submenu_id ,s.id, s.design_type_id ,2)
                if(i.submenu_id == s.id && s.design_type_id == 2) {
                    i.submenuTabs = zagarTabs.data.data
                }  else 
                if(i.submenu_id == s.id && s.design_type_id == 4) {
                    i.submenuTabs = salonTabs.data.data
                } 
            })
        })
        console.log(data)
        this.setState(data);
        this.setState({
            submenuList: submenuList.data.data,
            salonTabs: salonTabs.data.data,
            zagarTabs: zagarTabs.data.data
        });
        // this.props.form.setFieldsValue(this.state)
    }

      handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
          if (!err) {
            try {
                const postData = await putHomePageData(values)
                message.success('Success')
            } catch (error) {
                message.error('Error', error.response.status)
              
            }
          }
        });
      }

       selectSubmenu = async (e, param) => {
        this.state.submenuList.map(async(s) => {
            console.log(s)
            if (s.design_type_id === 2 && s.id == e) {
                
                eval('this.state.' + param + '='+ JSON.stringify(this.state.zagarTabs) ) 
                this.setState({changed: true})
            }
            else if (s.design_type_id === 4 && s.id == e) {
                eval('this.state.' + param + '='+ JSON.stringify(this.state.salonTabs) ) 
                this.setState({changed: true})
            }
            
             
        })
      }

    render () {
        const uploadProps = {
            action: process.env.REACT_APP_ROOT_API + '/uploads/',
            listType: 'picture',
            name: 'uri',
            headers: {
                Authorization: getAccessToken(),
            },
          };



        const { getFieldDecorator } = this.props.form;

        return (
             
            <Form onSubmit={this.handleSubmit}>
                
                <Form.Item label="Title">
                {getFieldDecorator('title', {
                    rules: [{
                    required: true, message: 'Please input your title',
                    }],
                    initialValue: this.state.title
                }, )(
                    <Input />
                )}
                </Form.Item>

                <Form.Item label="Head Text">
                {getFieldDecorator('headText', {
                    rules: [{
                    required: true, message: 'Please input your Head Text',
                    }],
                    initialValue: this.state.headText
                })(
                    // <Input.TextArea style={{minHeight: 125}}    />
                    <ReactQuill />
                )}
                </Form.Item>

                  <Tabs defaultActiveKey="1" >
                  {/* 1111111111111 */}
                    <TabPane tab="Image one" key="1">
                        <Form.Item label="Image one" extra="Image one">
                            {getFieldDecorator('images.image_one.image_url', {
                                valuePropName: 'uri',
                                getValueFromEvent: this.normFile,
                                initialValue: this.state.images.image_one && this.state.images.image_one.image_url

                            })(
                                <Upload {...uploadProps}>
                                    <Button>
                                        <Icon type="upload" /> Upload
                                    </Button>
                                </Upload>
                            )}
                        </Form.Item>
                        <Form.Item label="Image one Mobile" extra="Image one Mobile">
                            {getFieldDecorator('images.image_one.mobile_image', {
                                valuePropName: 'uri',
                                getValueFromEvent: this.normFile,
                                initialValue: this.state.images.image_one && this.state.images.image_one.mobile_image

                            })(
                                <Upload {...uploadProps}>
                                    <Button>
                                        <Icon type="upload" /> Upload
                                    </Button>
                                </Upload>
                            )}
                        </Form.Item>

                        <Form.Item label="Image one Hover Text">
                            {getFieldDecorator('images.image_one.hover_text', {
                                rules: [{
                                required: true, message: 'Please input your Image one Hover Text',
                                }],
                                initialValue: this.state.images.image_one && this.state.images.image_one.hover_text

                            })(
                                <Input     />
                            )}
                        </Form.Item>

                        <Form.Item label="Select Image one SubMenu" hasFeedback>
                            {getFieldDecorator('images.image_one.submenu_id', {
                                rules: [{  message: 'Select Image one SubMenu!' }],
                                // initialValue: this.state.images.image_one.submenu_id
                                initialValue: this.state.images.image_one && this.state.images.image_one.submenu_id

                            })(
                                <Select placeholder="Please select your Select Image one SubMenu"
                                    onChange={ (e) =>{ this.selectSubmenu(e,'images.image_one.submenuTabs');} }
                                >
                                    {this.state.submenuList.map(d => (
                                        <Select.Option key={d.id}>{d.name}</Select.Option>
                                    ))}
                                </Select>,
                            )}
                        </Form.Item>

                        <Form.Item label="Select Image one SubMenu Tab" hasFeedback>
                            {getFieldDecorator('images.image_one.submenu_tab_id', {
                                rules: [{  message: 'Select Image one SubMenu Tab!' }],
                                // initialValue: this.state.images.image_one.submenu_id
                                initialValue: this.state.images.image_one && this.state.images.image_one.submenu_tab_id

                            })(
                                <Select placeholder="Please select your Select Image one SubMenu Tab">
                                    {this.state.images.image_one.submenuTabs.map(d => (
                                        <Select.Option key={d.id}>{d.name}</Select.Option>
                                    ))}
                                </Select>,
                            )}
                        </Form.Item>
                    </TabPane>
                    {/* 22222222222222222222222222 */}
                    <TabPane tab="Image two" key="2">
                        <Form.Item label="Image two" extra="Image two">
                        {getFieldDecorator('images.image_two.image_url', {
                            valuePropName: 'uri',
                            getValueFromEvent: this.normFile,
                            initialValue: this.state.images.image_two && this.state.images.image_two.image_url

                            
                        })(
                            <Upload {...uploadProps}>
                                <Button>
                                    <Icon type="upload" /> Upload
                                </Button>
                            </Upload>
                        )}
                        </Form.Item>
                        <Form.Item label="Image two Mobile" extra="Image two Mobile">
                        {getFieldDecorator('images.image_two.mobile_image', {
                            valuePropName: 'uri',
                            getValueFromEvent: this.normFile,
                            initialValue: this.state.images.image_two && this.state.images.image_two.mobile_image

                        })(
                            <Upload {...uploadProps}>
                                <Button>
                                    <Icon type="upload" /> Upload
                                </Button>
                            </Upload>
                        )}
                        </Form.Item>

                        <Form.Item label="Image two Hover Text">
                            {getFieldDecorator('images.image_two.hover_text', {
                                rules: [{
                                required: true, message: 'Please input your Image two Hover Text',
                                }],
                                initialValue: this.state.images.image_two && this.state.images.image_two.hover_text

                            })(
                                <Input     />
                            )}
                        </Form.Item>

                        <Form.Item label="Select Image two SubMenu" hasFeedback>
                        {getFieldDecorator('images.image_two.submenu_id', {
                            rules: [{  message: 'Please select your Image two SubMenu!' }],
                            initialValue: this.state.images.image_two && this.state.images.image_two.submenu_id

                        })(
                            <Select placeholder="Please select your Image two SubMenu"
                            onChange={ (e) =>{ this.selectSubmenu(e,'images.image_two.submenuTabs');} }
                            >
                                {this.state.submenuList.map(d => (
                                    <Select.Option key={d.id}>{d.name}</Select.Option>
                                ))}
                            </Select>,
                        )}
                        </Form.Item>
                        <Form.Item label="Select Image one SubMenu Tab" hasFeedback>
                            {getFieldDecorator('images.image_two.submenu_tab_id', {
                                rules: [{  message: 'Select Image one SubMenu Tab!' }],
                                // initialValue: this.state.images.image_one.submenu_id
                                initialValue: this.state.images.image_two && this.state.images.image_two.submenu_tab_id

                            })(
                                <Select placeholder="Please select your Select Image one SubMenu Tab">
                                    {this.state.images.image_two.submenuTabs.map(d => (
                                        <Select.Option key={d.id}>{d.name}</Select.Option>
                                    ))}
                                </Select>,
                            )}
                        </Form.Item>
                    </TabPane>
                    {/* 333333333333333333333333 */}
                    <TabPane tab="Image three" key="3">
                        <Form.Item label="Image three" extra="Image three">
                        {getFieldDecorator('images.image_three.image_url', {
                            valuePropName: 'uri',
                            getValueFromEvent: this.normFile,
                            initialValue: this.state.images.image_three && this.state.images.image_three.image_url

                        })(
                            <Upload {...uploadProps}>
                                <Button>
                                    <Icon type="upload" /> Upload
                                </Button>
                            </Upload>
                        )}
                        </Form.Item>
                        <Form.Item label="Image three Mobile" extra="Image three Mobile">
                        {getFieldDecorator('images.image_three.mobile_image', {
                            valuePropName: 'uri',
                            getValueFromEvent: this.normFile,
                            initialValue: this.state.images.image_three && this.state.images.image_three.mobile_image

                        })(
                            <Upload {...uploadProps}>
                                <Button>
                                    <Icon type="upload" /> Upload
                                </Button>
                            </Upload>
                        )}
                        </Form.Item>

                        <Form.Item label="Image three Hover Text">
                            {getFieldDecorator('images.image_three.hover_text', {
                                rules: [{
                                required: true, message: 'Please input your Image three Hover Text',
                                }],
                                initialValue: this.state.images.image_three && this.state.images.image_three.hover_text

                            })(
                                <Input     />
                            )}
                        </Form.Item>

                        <Form.Item label="Select Image three SubMenu" hasFeedback>
                        {getFieldDecorator('images.image_three.submenu_id', {
                            rules: [{  message: 'Please select your submenu!' }],
                            initialValue: this.state.images.image_three && this.state.images.image_three.submenu_id

                        })(
                            <Select placeholder="Please select your submenu"
                            onChange={ (e) =>{ this.selectSubmenu(e,'images.image_three.submenuTabs');} }
                            >
                                {this.state.submenuList.map(d => (
                                    <Select.Option key={d.id}>{d.name}</Select.Option>
                                ))}
                            </Select>,
                        )}
                        </Form.Item>
                        <Form.Item label="Select Image one SubMenu Tab" hasFeedback>
                            {getFieldDecorator('images.image_three.submenu_tab_id', {
                                rules: [{  message: 'Select Image one SubMenu Tab!' }],
                                // initialValue: this.state.images.image_one.submenu_id
                                initialValue: this.state.images.image_three && this.state.images.image_three.submenu_tab_id

                            })(
                                <Select placeholder="Please select your Select Image one SubMenu Tab">
                                    {this.state.images.image_three.submenuTabs.map(d => (
                                        <Select.Option key={d.id}>{d.name}</Select.Option>
                                    ))}
                                </Select>,
                            )}
                        </Form.Item>
                    </TabPane>
                            {/* 444444444444444444444444444 */}

                    <TabPane tab="Image four" key="4">
                        <Form.Item label="Image four" extra="Image four">
                        {getFieldDecorator('images.image_four.image_url', {
                            valuePropName: 'uri',
                            getValueFromEvent: this.normFile,
                            initialValue: this.state.images.image_four && this.state.images.image_four.image_url

                        })(
                            <Upload {...uploadProps}>
                                <Button>
                                    <Icon type="upload" /> Upload
                                </Button>
                            </Upload>
                        )}
                        </Form.Item>
                        <Form.Item label="Image four Mobile" extra="Image four Mobile">
                        {getFieldDecorator('images.image_four.mobile_image', {
                            valuePropName: 'uri',
                            getValueFromEvent: this.normFile,
                            initialValue: this.state.images.image_four && this.state.images.image_four.mobile_image

                        })(
                            <Upload {...uploadProps}>
                                <Button>
                                    <Icon type="upload" /> Upload
                                </Button>
                            </Upload>
                        )}
                        </Form.Item>

                        <Form.Item label="Image four Hover Text">
                            {getFieldDecorator('images.image_four.hover_text', {
                                rules: [{
                                required: true, message: 'Please input your Hover Text',
                                }],
                                initialValue: this.state.images.image_four && this.state.images.image_four.hover_text

                            })(
                                <Input     />
                            )}
                        </Form.Item>

                        <Form.Item label="Select Image four SubMenu" hasFeedback>
                        {getFieldDecorator('images.image_four.submenu_id', {
                            rules: [{  message: 'Please select your submenu!' }],
                            initialValue: this.state.images.image_four && this.state.images.image_four.submenu_id

                        })(
                            <Select placeholder="Please select your submenu"
                            onChange={ (e) =>{ this.selectSubmenu(e,'images.image_four.submenuTabs');} }
                            >
                                {this.state.submenuList.map(d => (
                                    <Select.Option key={d.id}>{d.name}</Select.Option>
                                ))}
                            </Select>,
                        )}
                        </Form.Item>
                        <Form.Item label="Select Image one SubMenu Tab" hasFeedback>
                            {getFieldDecorator('images.image_four.submenu_tab_id', {
                                rules: [{  message: 'Select Image one SubMenu Tab!' }],
                                // initialValue: this.state.images.image_one.submenu_id
                                initialValue: this.state.images.image_four && this.state.images.image_four.submenu_tab_id

                            })(
                                <Select placeholder="Please select your Select Image one SubMenu Tab">
                                    {this.state.images.image_four.submenuTabs.map(d => (
                                        <Select.Option key={d.id}>{d.name}</Select.Option>
                                    ))}
                                </Select>,
                            )}
                        </Form.Item>
                    </TabPane>
                        {/*55555555555555555555 */}

                    <TabPane tab="Image five" key="5">
                    <Form.Item label="Image five" extra="Image five">
                        {getFieldDecorator('images.image_five.image_url', {
                            valuePropName: 'uri',
                            getValueFromEvent: this.normFile,
                            initialValue: this.state.images.image_five && this.state.images.image_five.image_url

                        })(
                            <Upload {...uploadProps}>
                                <Button>
                                    <Icon type="upload" /> Upload
                                </Button>
                            </Upload>
                        )}
                        </Form.Item>
                        <Form.Item label="Image five Mobile" extra="Image five Mobile">
                        {getFieldDecorator('images.image_five.mobile_image', {
                            valuePropName: 'uri',
                            getValueFromEvent: this.normFile,
                            initialValue: this.state.images.image_five && this.state.images.image_five.mobile_image

                        })(
                            <Upload {...uploadProps}>
                                <Button>
                                    <Icon type="upload" /> Upload
                                </Button>
                            </Upload>
                        )}
                        </Form.Item>

                        <Form.Item label="Image five Hover Text">
                            {getFieldDecorator('images.image_five.hover_text', {
                                rules: [{
                                required: true, message: 'Please input your Hover Text',
                                }],
                                initialValue: this.state.images.image_five && this.state.images.image_five.hover_text

                            })(
                                <Input     />
                            )}
                        </Form.Item>

                        <Form.Item label="Select Image five" hasFeedback>
                        {getFieldDecorator('images.image_five.submenu_id', {
                            rules: [{  message: 'Please select your submenu!' }],
                            initialValue: this.state.images.image_five && this.state.images.image_five.submenu_id

                        })(
                            <Select placeholder="Please select your submenu"
                            onChange={ (e) =>{ this.selectSubmenu(e,'images.image_five.submenuTabs');} }
                            >
                                {this.state.submenuList.map(d => (
                                    <Select.Option key={d.id}>{d.name}</Select.Option>
                                ))}
                            </Select>,
                        )}
                        </Form.Item>
                        <Form.Item label="Select Image one SubMenu Tab" hasFeedback>
                            {getFieldDecorator('images.image_one.submenu_tab_id', {
                                rules: [{  message: 'Select  SubMenu Tab!' }],
                                // initialValue: this.state.images.image_one.submenu_id
                                initialValue: this.state.images.image_five && this.state.images.image_five.submenu_tab_id

                            })(
                                <Select placeholder="Please select your Select  SubMenu Tab">
                                    {this.state.images.image_five.submenuTabs.map(d => (
                                        <Select.Option key={d.id}>{d.name}</Select.Option>
                                    ))}
                                </Select>,
                            )}
                        </Form.Item>
                    </TabPane>
                    {/* 66666666666666666666666 */}

                    <TabPane tab="Image Six" key="6">
                    <Form.Item label="Image Six" extra="Image Six">
                        {getFieldDecorator('images.image_six.image_url', {
                            valuePropName: 'uri',
                            getValueFromEvent: this.normFile,
                            initialValue: this.state.images.image_six && this.state.images.image_six.image_url

                        })(
                            <Upload {...uploadProps}>
                                <Button>
                                    <Icon type="upload" /> Upload
                                </Button>
                            </Upload>
                        )}
                        </Form.Item>
                        <Form.Item label="Image Six Mobile" extra="Image Six Mobile">
                        {getFieldDecorator('images.image_six.mobile_image', {
                            valuePropName: 'uri',
                            getValueFromEvent: this.normFile,
                            initialValue: this.state.images.image_six && this.state.images.image_six.mobile_image

                        })(
                            <Upload {...uploadProps}>
                                <Button>
                                    <Icon type="upload" /> Upload
                                </Button>
                            </Upload>
                        )}
                        </Form.Item>

                        <Form.Item label="Image Six Hover Text">
                            {getFieldDecorator('images.image_six.hover_text', {
                                rules: [{
                                required: true, message: 'Please input your Hover Text',
                                }],
                                initialValue: this.state.images.image_six && this.state.images.image_six.hover_text

                            })(
                                <Input     />
                            )}
                        </Form.Item>

                        <Form.Item label="Select Image Six SubMenu" hasFeedback>
                        {getFieldDecorator('images.image_six.submenu_id', {
                            rules: [{  message: 'Please select your submenu!' }],
                            initialValue: this.state.images.image_six && this.state.images.image_six.submenu_id

                        })(
                            <Select placeholder="Please select your submenu"
                            onChange={ (e) =>{ this.selectSubmenu(e,'images.image_six.submenuTabs');} }
                            >
                                {this.state.submenuList.map(d => (
                                    <Select.Option key={d.id}>{d.name}</Select.Option>
                                ))}
                            </Select>,
                        )}
                        </Form.Item>
                        <Form.Item label="Select Image one SubMenu Tab" hasFeedback>
                            {getFieldDecorator('images.image_six.submenu_tab_id', {
                                rules: [{  message: 'Select  SubMenu Tab!' }],
                                // initialValue: this.state.images.image_one.submenu_id
                                initialValue: this.state.images.image_six && this.state.images.image_six.submenu_tab_id

                            })(
                                <Select placeholder="Please select your Select  SubMenu Tab">
                                    {this.state.images.image_six.submenuTabs.map(d => (
                                        <Select.Option key={d.id}>{d.name}</Select.Option>
                                    ))}
                                </Select>,
                            )}
                        </Form.Item>
                    </TabPane>
                </Tabs>
                <hr />
                {/* Below Section----------- */}
                <Form.Item label="Centeral Title">
                    {getFieldDecorator('centeral_title', {
                        rules: [{
                        required: true, message: 'Please input your Centeral Title',
                        }],
                        initialValue: this.state.centeral_title

                    })(
                        <Input     />
                    )}
                </Form.Item>

                <Form.Item label="Centeral Text">
                    {getFieldDecorator('centeral_text', {
                        rules: [{
                        required: true, message: 'Please input your Centeral Text',
                        }],
                        initialValue: this.state.centeral_text

                    })(
                        <Input.TextArea style={{minHeight: 125}}    />
                    )}
                </Form.Item>

                <Form.Item label="Select Central Product SubMenu" hasFeedback>
                    {getFieldDecorator('central_sub_menu_id', {
                        rules: [{  message: 'Please select your submenu!' }],
                        initialValue: this.state.central_sub_menu_id

                    })(
                        <Select placeholder="Please select your submenu">
                            {this.state.submenuList.map(d => (
                                <Select.Option key={d.id}>{d.name}</Select.Option>
                            ))}
                        </Select>,
                    )}
                </Form.Item>

                <Form.Item label="Contnet Product Title">
                    {getFieldDecorator('content_product.title', {
                        rules: [{
                        required: true, message: 'Contet Product Title',
                        }],
                        initialValue: this.state.content_product.title

                    })(
                        <Input     />
                    )}
                </Form.Item>

                <Form.Item label="Contet Product Text">
                    {getFieldDecorator('content_product.text', {
                        rules: [{
                        required: true, message: 'Please input your Contet Product Title',
                        }],
                        initialValue: this.state.content_product.text

                    })(
                        <Input.TextArea style={{minHeight: 125}}    />
                    )}
                </Form.Item>

                <Form.Item label="Select Contet Product SubMenu" hasFeedback>
                    {getFieldDecorator('content_product.sub_menu_id', {
                        rules: [{  message: 'Please select your submenu!' }],
                        initialValue: this.state.content_product.sub_menu_id

                    })(
                        <Select placeholder="Please select your submenu">
                            {this.state.submenuList.map(d => (
                                <Select.Option key={d.id}>{d.name}</Select.Option>
                            ))}
                        </Select>,
                    )}
                </Form.Item>

                <Form.Item label="Contet Product Image One" extra="Contet Product Image One">
                {getFieldDecorator('content_product.images.image_one', {
                    valuePropName: 'uri',
                    getValueFromEvent: this.normFile,
                    initialValue: this.state.content_product.images && this.state.content_product.images.image_one

                })(
                    <Upload {...uploadProps}>
                        <Button>
                            <Icon type="upload" /> Upload
                        </Button>
                    </Upload>
                )}
                </Form.Item>

                <Form.Item label="Contet Product Image two" extra="Contet Product Image two">
                {getFieldDecorator('content_product.images.image_two', {
                    valuePropName: 'uri',
                    getValueFromEvent: this.normFile,
                    initialValue: this.state.content_product.images && this.state.content_product.images.image_two

                })(
                    <Upload {...uploadProps}>
                        <Button>
                            <Icon type="upload" /> Upload
                        </Button>
                    </Upload>
                )}
                </Form.Item>

                <Form.Item label="Contet Product Image three" extra="Contet Product Image three">
                {getFieldDecorator('content_product.images.image_three', {
                    valuePropName: 'uri',
                    getValueFromEvent: this.normFile,
                    initialValue: this.state.content_product.images && this.state.content_product.images.image_three

                })(
                    <Upload {...uploadProps}>
                        <Button>
                            <Icon type="upload" /> Upload
                        </Button>
                    </Upload>
                )}
                </Form.Item>
                
                {/* Meta Part       ------------------------- */}
                <Form.Item label="Title meta  ">
                    {getFieldDecorator('meta.title', {
                        rules: [{
                        required: true, message: 'Please input your meta title',
                        }],
                        initialValue: this.state.meta.title

                    })(
                        <Input     />
                    )}
                </Form.Item>

                <Form.Item label="meta.description">
                    {getFieldDecorator('meta.description', {
                        rules: [{
                        required: true, message: 'Please input your meta.description',
                        }],
                        initialValue: this.state.meta.description

                    })(
                        <Input.TextArea style={{minHeight: 125}}    />
                    )}
                </Form.Item>

                <Form.Item label="meta.og_image" extra="meta.og_image">
                    {getFieldDecorator('meta.og_image', {
                        valuePropName: 'uri',
                        getValueFromEvent: this.normFile,
                        initialValue: this.state.meta && this.state.meta.og_image

                    })(
                        <Upload {...uploadProps}>
                            <Button>
                                <Icon type="upload" /> Upload
                            </Button>
                        </Upload>
                    )}
                </Form.Item>




                <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
                </Form.Item>

            </Form>
        )
    }
}



export default Form.create({ name: 'homeForm' })(HomeForm);