import React, { PureComponent } from 'react';
import { Menu, Row, Col} from 'antd';
import DynamicPages from './DynamicPages'
import SubmenuTable from './SubmenuTable'

export default class Submenu extends PureComponent {

  render() {
    return <div>
        <Row>
            <Col span={4}><DynamicPages /></Col>
            <Col span={19}><SubmenuTable /></Col>  
        </Row>
    </div>
  }
}