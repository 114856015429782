import React, { PureComponent } from 'react';
import {  Row, Col} from 'antd';
import DynamicPages from './DynamicPages'
import ZagarTabsTable from './ZagarTabsTable'

export default class SalonTabs extends PureComponent {
  state = {};

  async componentDidMount() {

  }

  render() {
    return <div>
        <Row>
            <Col span={4}><DynamicPages /></Col>
            <Col span={19}><ZagarTabsTable /></Col>  
        </Row>
    </div>
  }
}