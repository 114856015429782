import React, { PureComponent } from 'react';
import {Row, Col} from 'antd';
import StaticMenus from './StaticPages'
import ConatctsServiceTable from './conatctsServiceTable'

export default class ConatctsService extends PureComponent {

  render() {
    return <div>
        <Row>
            <Col span={4}><StaticMenus /></Col>
            <Col span={19}>
                <ConatctsServiceTable />
            </Col>  
        </Row>
    </div>
  }
}