import React from 'react';
import { Form, Icon, Input, Button } from 'antd';

import '../styles/sign-in.scss';
import { signIn } from '../api'
import { saveState } from '../services/localStorage';

class NormalLoginForm extends React.Component {
  state = { isLoading: false };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        try {
          this.setState({ isLoading: true });
          const { data: { accessToken } } = await signIn({ ...values });
          this.setState({ isLoading: false });
          saveState('accessToken', accessToken);
          this.props.login();
          this.props.history.push('/');
        } catch (error) {
          
        }
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className='login-form'>
        <h1 className='login-form__title'>Sign in</h1>
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [{ required: true, message: 'Please input your email!' }],
          })(
            <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your Password!' }],
          })(
            <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" />
          )}
        </Form.Item>

        <Button type="primary" htmlType="submit" size='large' className='login-form__button' loading={this.state.isLoading}>
          Log in
        </Button>
      </Form>
    );
  }
}

export default Form.create({ name: 'normal_login' })(NormalLoginForm);

