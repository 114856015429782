import React, { useState } from 'react';
import Router from './components/Router'

import { getAccessToken } from './services/localStorage';

function App() {
  const [isAuth, setIsAuth] = useState(!!getAccessToken())
  const login = () =>  setIsAuth(true);
  const logout = () => setIsAuth(false);
  return (
    <Router
      isAuthenticated={isAuth}
      login={login}
      logout={logout}
    />
  );
}

export default App;
